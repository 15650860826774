import Main from "../layouts/Main";
import "./PlanDo.css";
export default function PlanDo() {
  return (
    <Main headerSecondary>
      <div id="plando">
        <section className="hero">
          <div className="d-none d-sm-block">
            <img
              src="/assets/img/Plando/Plando_left_laptop.png"
              alt=""
              className="img1"
            />
          </div>
          <div className="">
            <div className="overflow-area">
              <div className="scrollable-object">
                <img
                  src="/assets/img/Plando/scrollable_home.jpg"
                  alt=""
                  className="img2-overlay"
                />
              </div>
            </div>
            <img
              src="/assets/img/Plando/Plando_front_laptop.png"
              alt=""
              className="img2"
            />
          </div>
          <div className="d-none d-sm-block">
            <img
              src="/assets/img/Plando/Plando_right_laptop.png"
              alt=""
              className="img3"
            />
          </div>
          <h1 className="plando-title">Plando</h1>
          <h2 className="plando-sub-title">
            Planner and Goal-tracking Web Application
          </h2>
        </section>
        <section className="prototype">
          <p>
            When PlanDo was first introduced, it was a paper planner
            commissioned by a YouTuber and created by a team of graphic
            designers. The YouTuber promoted and sold it on social media.
            However, as users began to use it, they requested additional
            features. The YouTuber then realized that converting the planner
            from a paper to a digital format would provide users with more
            options and eliminate the constraints of the paper planner.
            Moreover, creating a digital version would reduce paper usage and
            allow users to access it on their mobile devices while on-the-go.
          </p>
          <a
            href="https://www.figma.com/proto/i1f0rZjBgqoesF5D2Snow3/High_Fid_Planner?page-id=0%3A1&type=design&node-id=1-28&viewport=505%2C482%2C0.05&scaling=min-zoom&starting-point-node-id=1%3A28"
            target="_blank"
            rel="noreferrer"
          >
            <button className="inter">View Figma Prototype</button>
          </a>
          <div className="py-6 py-sm-9"></div>
          <div className="video-container">
            <video
              width="100%"
              // height="100%"
              autoPlay
              loop
              muted
              playsInline
              preload="none"
              style={{ background: "transparent", objectFit: "cover" }}
            >
              <source src={"/assets/video/plando.mp4"} type="video/mp4" />
            </video>
          </div>
          <div className="py-6 py-sm-9"></div>
        </section>
        <section className="overview">
          <div className="header">
            <span className="inter">Overview</span>
          </div>
          <div className="overview-image mx-auto">
            <img
              src="/assets/img/Plando/Overview_Plando.png"
              alt=""
              className="img-fluid d-none d-sm-block"
            />
            <img
              src="/assets/img/Plando/Overview_Plando_Mobile.png"
              alt=""
              className="img-fluid d-block d-sm-none mx-auto"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            Stakeholders Needs
          </h3>
          <p className="mx-auto">
            Stakeholders wanted to design a digital planner, which is more than
            a paper planner, and it is possible to <b>record goals</b> and
            <b> track the process</b> of achieving purposes in it. It allows
            users to <b>customize their planner pages</b> and
            <b> does not have the restrictions of paper planners</b> . It should
            be <b>visually attractive</b> and
            <b>
              {" "}
              flexible so everyone can design their planner according to their
              taste
            </b>
            . <br />
            <br /> Stakeholders hired UX/UI designers to design a creative
            digital planner
            <b> to satisfy the demands of their former customers</b>, who
            utilized their paper planners while
            <b> also attracting new clients</b>. <br />
            <br />
            <b>
              The digital planner should be conducted with the following
              capabilities:
            </b>
            <br />
            <br />
            <ul>
              <li>
                Having capabilities like the ability to add reminders that are
                not present in paper planners.
              </li>
              <li>
                The ability to monitor the progress of individual targets.
              </li>
              <li>Flexible and appealing visual space for all tastes.</li>
              <li>
                Assisting users in achieving their objectives and inspiring
                them.
              </li>
            </ul>
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center pt-1">
            Problem Statement
          </h3>
          <p className="mx-auto">
            <ul>
              <li>
                Limitation of users in adding tasks to the required number and
                in desired topics.
              </li>
              <li>
                The inability to organize the planner's sections based on
                personal preferences.
              </li>
              <li>Forced and rigid visuals.</li>
              <li>
                Inability to follow and track the process of accomplishing tasks
                and goals.
              </li>
            </ul>
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-11">Goals</h3>
          <p className="mx-auto">
            <ul>
              <li>
                Design a digital planner that helps users establish both
                short-term and long-term personal goals
              </li>
              <li>
                Guide users through the process of achieving their goals through
                data visualization
              </li>
              <li>
                Have the option to customize their planner based on their
                preferences.
              </li>
            </ul>
          </p>
          <div className="py-8"></div>
          <div className="header ">
            <span className="inter">The Process</span>
          </div>
          <p className="mx-auto px-16">
            My fourth months design sprint was divided into five stages: It
            should be noted that iteration is essential at every stage.
          </p>
          <div className="overview-image mx-auto">
            <img
              src="/assets/img/Plando/The process_plando.png"
              alt=""
              className="img-fluid d-none d-sm-block"
            />
            <img
              src="/assets/img/Plando/The process_plando_Mobile.png"
              alt=""
              className="img-fluid d-block d-sm-none mx-auto"
            />
          </div>
          <div className="py-5 py-sm-15"></div>
          <div className="header">
            <span className="inter">Empathize & Define</span>
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">Interview</h3>
          <p className="mx-auto">
            Initial interviews with users of the paper version of the planner
            and people who had commented on YouTube posts about how to improve
            the planner helped me gain more knowledge about users' expectations,
            mindsets, and behaviors.
          </p>
          <div className="interview-image mx-auto">
            <img
              src="/assets/img/Plando/Interview.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">Persona</h3>
          <p className="mx-auto mt-1">
            Based on the data from the interview, it was possible to single out
            the user’s persona that helped us focus attention on important parts
            and avoid unnecessary features.
          </p>
          <div className="persona-image mx-auto">
            <img
              src="/assets/img/Plando/Persona_plando.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-7 py-sm-10"></div>
          <div className="header ">
            <span className="inter">Planing & Ideate</span>
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            Competitive Analysis
          </h3>
          <p className="mx-auto mt-1">
            In order to understand what solutions are already on the market, we
            conducted a competitor analysis together with the team.
            <br />
            <br />
            <b>we conduct a competitive analysis in 4 steps:</b>
            <br />
            <br />
            <ol>
              <li>Identify our competitors </li>
              <li>Gather information about our competitors</li>
              <li>Analyze our competitors’ strengths and weaknesses</li>
              <li>Determine our competitive advantage</li>
            </ol>
          </p>
          <div className="competitive-image mx-auto">
            <img
              src="/assets/img/Plando/Competetive_Analysis_plando.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">Site Map</h3>
          <div className="userflow-image mx-auto">
            <img
              src="/assets/img/Plando/UserFlow_plando.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-7 py-sm-10"></div>
          <div className="header ">
            <span className="inter">PROTOTYPE & DESIGN</span>
          </div>
          <p className="mx-auto mt-16 px-sm-7">
            With the objective of developing an all-inclusive design system, I
            crafted a set of UI with each component meticulously documented to
            explain its various states. The system also encompasses a
            comprehensive guide to interaction principles and offers contextual
            insights on how to make the best use of the UI components.
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-8 mt-sm-16">
            UI Kit
          </h3>
          <p className="mx-auto mt-5 px-5">
            With the objective of developing an all-inclusive design system, I
            crafted a set of UI with each component meticulously documented to
            explain its various states. The system also encompasses a
            comprehensive guide to interaction principles and offers contextual
            insights on how to make the best use of the UI components.{" "}
          </p>
          <div className="uikit-image mx-auto">
            <img
              src="/assets/img/Plando/UIKit.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            Sketch and Low-fidelity wireframe
          </h3>
          <div className="sketch-image mx-auto">
            <img
              src="/assets/img/Plando/LowFi.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">Grid System</h3>
          <div className="grid-image mx-auto">
            <img
              src="/assets/img/Plando/Grid_System.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-sm-5"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">Responsive</h3>
          <div className="responsive-image mx-auto">
            <img
              src="/assets/img/Plando/respo.gif"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-9"></div>
          <div className="header ">
            <span className="inter">Test & Iterations</span>
          </div>
          <div className="py-2"></div>
          <p className="mx-auto mt-sm-16 px-sm-5">
            During the process of designing UI kits and wireframes, I had the
            opportunity to conduct multiple rounds of testing and receive
            valuable feedback from my mentors. As a result, I made several
            iterations to my design in order to improve its overall usability
            and functionality.
            <br />
            In the following sections, I will provide a detailed comparison
            between the initial wireframe and the final version, highlighting
            the key changes and improvements made along the way. By iterating on
            the design and incorporating feedback, I was able to create a final
            product that effectively met the needs of the user while still
            adhering to the project's requirements and constraints.
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-13">
            User Tests and Iterations
          </h3>
          <div className="py-2"></div>
          <p className="mx-auto mt-0 px-sm-3">
            Initially, the YouTuber emphasized the importance of maintaining the
            paper planner's original layout and only incorporating additional
            features. However, after designing a preliminary layout that
            imitated the paper planner, we tested it and found that it was too
            inflexible for users and hindered the tracking and visualization of
            data. To enhance the user experience, we conducted a competitor
            analysis and assessed user pain points and needs. Based on this
            research, we made the decision to shift to a dashboard-style layout
            that enables users to customize the design and arrange tasks in a
            way that best suits their preferences.
            <br />
            <br />
            After deciding to change the layout, we presented the new design to
            stakeholders for feedback and discussion. They approved the
            modifications, and we continued refining the design until it met the
            necessary standards. The iterative design process allowed us to
            ensure that the final product catered to the requirements of both
            users and stakeholders, ultimately resulting in a superior user
            experience and a more successful product.
          </p>
          <div className="usertest-image mx-auto">
            <img
              src="/assets/img/Plando/UserTest.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">A/B Testing</h3>
          <p className="mx-auto px-sm-3">
            We conducted user testing with five participants to obtain genuine
            feedback on our prototype. During the testing process, we instructed
            the users to create a free account and add a new task to the 'My
            Day' page. They were asked to assign a tag and a color to the tag,
            make it a goal, mark a task as done, and then check the daily,
            weekly, and monthly pages to evaluate the process. We carefully
            observed their behaviour as they navigated through the prototype to
            gain insight into what was working well and what wasn't.
            <br />
            <br />
            Based on the feedback we received, we made adjustments to our
            prototype to improve the user experience and make it more aligned
            with users' expectations. User testing is a critical step in
            refining and iterating the prototype, as it helps to identify
            missing functionalities and areas for improvement.
          </p>
          <div className="testing-image mx-auto">
            <img
              src="/assets/img/Plando/ABTesting.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="ab-testing-image mx-auto">
            <img
              src="/assets/img/Plando/ABTesting_2.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-5 py-sm-10"></div>
          <div className="header">
            <span className="inter">DELIVER & LAUNCH</span>
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            High Fidelity Wireframes
          </h3>
          <div className="high-fidelity-image mx-auto">
            <img
              src="/assets/img/Plando/HighFi.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-4 py-sm-9"></div>
          <div className="header">
            <span className="inter">REFLECTIONS</span>
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            What did I learn?
          </h3>
          <p className="mx-auto">
            <ul className="ms-sm-5">
              <li>
                Developing <b>problem-solving skills</b> and
                <b> effective teamwork </b>
                in challenging situations is crucial. This requires{" "}
                <b>clear communication</b>, collaboration, and the{" "}
                <b>ability to adapt to changes</b> quickly.
              </li>
              <li>
                <b>Providing solutions </b>that cater to{" "}
                <b>both technical and non-technical</b> users is essential. This
                can be achieved through careful consideration{" "}
                <b>of user requirements and feedback</b>.
              </li>
              <li>
                <b>Managing time and project resources </b>effectively while
                dealing with <b>multiple iterations</b> and user testing is
                critical. It is essential to have a well-planned project
                <b> timeline</b>, with regular check-ins to ensure the project
                stays on track.
              </li>
              <li>
                <b>Prioritizing user needs</b> and conducting user research at
                every stage of the design process is vital.{" "}
                <b>Documenting each step</b> and achievement throughout the
                design process can help ensure the project stays{" "}
                <b>focused on the user's requirements and goals</b>.
              </li>
            </ul>
            <br />
            <b className="ms-sm-5">
              Additionally, I learned the importance of:
            </b>
            <ul className="ms-sm-5">
              <li>
                <b>Staying up-to-date</b> with latest design tools and
                technologies to deliver the best possible solution.
              </li>
              <li>
                <b>Engaging with stakeholders</b> and keeping them{" "}
                <b>informed throughout the project</b> to ensure buy-in and
                support for the final design.
              </li>
              <li>
                Encouraging constructive <b>feedback</b> from team members,
                clients, and users to drive the project forward and make
                improvements.
              </li>
            </ul>
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center text-capitalize">
            What can we do next?
          </h3>
          <p className="mx-auto">
            <ul className="ms-sm-5">
              <li>
                To <b>enhance</b> the user experience and <b>engagement</b>, I
                plan to incorporate <b>gamification</b> into the design that
                encourages users to stay motivated and{" "}
                <b>committed to their goals and habits</b>. This can be achieved
                through elements such as <b>badges, and rewards</b>.
              </li>
              <li>
                In addition to gamification, I aim to include a{" "}
                <b>social sharing feature </b>that enables users to showcase
                their daily progress and achievements with their friends and
                followers on platforms such as Instagram and TikTok. This
                feature will not only help users celebrate their successes but
                also encourage them to <b> remain accountable and motivated</b>.
              </li>
              <li>
                To ensure that the design is accessible and user-friendly, I
                will also focus on designing a <b>mobile version </b>of the
                application. This will allow users to access the platform on the
                go and seamlessly integrate the app into their{" "}
                <b>daily routines.</b> Overall, these features will improve the
                user experience and help users achieve their goals in a{" "}
                <b>fun and interactive way</b>.
              </li>
            </ul>
          </p>
          <div className="py-10"></div>
        </section>
      </div>
    </Main>
  );
}
