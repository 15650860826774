import AppRoutes from "./routes/AppRoutes";
import { QueryProvider } from "./utils/QueryProvider";
import { HelmetProvider, Helmet } from "react-helmet-async";

import "./sass/App.scss";

function App() {
  return (
    <HelmetProvider>
      <QueryProvider>
        <Helmet
          bodyAttributes={{
            style: `background-color : #ffffff`,
          }}
        />
        <AppRoutes />
      </QueryProvider>
    </HelmetProvider>
  );
}

export default App;
