import Main from "../layouts/Main";
import { BsArrowRight } from "react-icons/bs";
import "./BlogPost.css";
import { Link, useLocation } from "react-router-dom";
export default function BlogPost3() {
  const location = useLocation();

  return (
    <Main>
      <div id="blog-post">
        <div className="py-15"></div>
        <section className="row">
          <div className="col-12 col-lg-7 first">
            <h1 className="fs40 fw-bold text-main">
              Examples of using AI in UX design projects{" "}
            </h1>
            <img
              src="/assets/img/Blog_3/Photo_Blog_3.png"
              alt=""
              className="img-fluid mt-8"
            />

            <p className="mt-8">
              There are several examples of using AI in UX design projects:
              <br />
              <br />
              <ol>
                <li>
                  Personalized product recommendations: Online retail companies
                  like Amazon use AI to analyze customer data and recommend
                  products based on their browsing and purchasing history.
                </li>
                <br />
                <li>
                  Chatbots for customer service: Many companies use AI-powered
                  chatbots to provide instant support and information to their
                  customers through their website or mobile app.
                </li>
                <br />
                <li>
                  Voice assistants: Voice assistants like Siri, Alexa, and
                  Google Assistant use AI to understand and respond to user
                  requests in a conversational manner.
                </li>
                <br />
                <li>
                  Adaptive interfaces: AI can be used to adjust the layout and
                  content of a website or app in real-time based on user
                  behavior and preferences, creating a personalized and
                  optimized experience.
                </li>
                <br />
                <li>
                  Emotion detection: AI-powered emotion detection technology can
                  be used to analyze facial expressions and other cues to
                  understand how users are feeling and respond appropriately.
                </li>
                <br />
                <li>
                  Predictive typing: AI can be used to predict what users are
                  trying to type, reducing the effort required to complete tasks
                  and improving the efficiency of the user experience.
                </li>
              </ol>
            </p>
            <br />
            <p>
              These are just a few examples of how AI is being used in UX design
              to create more personalized, intuitive, and efficient user
              experiences. The potential applications of AI in UX design are
              numerous and constantly evolving, making it an exciting area of
              growth for the field.
            </p>
          </div>
          <div className="d-none d-lg-block col-lg-5 second">
            <div className="more-topics">
              <h4 className="fs20 mb-6">More Topics:</h4>
              {location?.pathname.toLowerCase() == "/blogpost_2" || (
                <Link to="/blogpost_2">
                  <div className="topic">
                    <span className="title">
                      The Impact of AI on UX Design.
                    </span>
                    <img
                      src="/assets/img/Blog_1/Small1.png"
                      alt=""
                      className="topic-img"
                    />
                  </div>
                </Link>
              )}
              {location?.pathname.toLowerCase() == "/blogpost_3" || (
                <Link to="/blogpost_3">
                  <div className="topic">
                    <span className="title">
                      Examples of using AI in UX design projects.
                    </span>
                    <img
                      src="/assets/img/Blog_1/Small2.png"
                      alt=""
                      className="topic-img"
                    />
                  </div>
                </Link>
              )}

              {location?.pathname.toLowerCase() == "/blogpost_4" || (
                <Link to="/blogpost_4">
                  <div className="topic">
                    <span className="title">
                      What kind of influence a UX designer can have on a
                      project?
                    </span>
                    <img
                      src="/assets/img/Blog_1/Small3.png"
                      alt=""
                      className="topic-img"
                    />
                  </div>
                </Link>
              )}

              {location?.pathname.toLowerCase() == "/blogpost_5" || (
                <Link to="/blogpost_5">
                  <div className="topic">
                    <span className="title">
                      How to be a professional UX Designer
                    </span>
                    <img
                      src="/assets/img/Blog_1/Small40.png"
                      alt=""
                      className="topic-img"
                    />
                  </div>
                </Link>
              )}

              {location?.pathname.toLowerCase() == "/blogpost_1" || (
                <Link to="/blogpost_1">
                  <div className="topic">
                    <span className="title">
                      How can you use chat GPT for UX research?
                    </span>
                    <img
                      src="/assets/img/Blog_1/Small5.png"
                      alt=""
                      className="topic-img"
                    />
                  </div>
                </Link>
              )}
            </div>
          </div>{" "}
          <div className="py-16"></div>
        </section>
      </div>
    </Main>
  );
}
