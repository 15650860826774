import Main from "../layouts/Main";
import { Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import axios, { Axios } from "axios";
import "./Contact.css";
import { useState } from "react";
// contact us page
export default function Contact() {
  const [buttonText, setButtonText] = useState("Send Message");
  const {
    register,
    handleSubmit,
    setError,
    reset,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });
  const onSubmit = (data) => {
    const pData = {
      name: getValues("name"),
      email: getValues("email"),
      message: getValues("message"),
    };
    axios
      .post("https://formspree.io/f/mgeqookp", pData)
      .then(function (response) {
        console.log(response);
        reset();
        setButtonText("Message Sent");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <Main>
      <section id="contact" className="row ">
        <div className="py-16 d-none d-sm-block"></div>
        <div className="py-5 d-block d-sm-none"></div>
        <div className="contact-details col-12 col-md-6 text-main ps-sm-9">
          <div className="py-6 py-lg-13"></div>
          <div className="d-block">Mahsa HM Ebrahim</div>
          <div className="d-block">UI/UX Designer</div>
          <div className="d-block">mahsa.hm.ebrahim@gmail.com</div>
          <div className="d-block">+1 (647) 563-1051</div>
          <div className="py-6 py-lg-0"></div>
        </div>
        <div className="col-12 col-md-6 justify-content-center ">
          <Form.Group className="mb-3">
            <Form.Label className="text-main">Full name:</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              className="border-main border-1 border"
              {...register("name", {
                required: "Required",
                pattern: {
                  value: /^[a-zA-Z0-9 ]+$/,
                  message: "only text is allowed",
                },
              })}
            />
            {errors.name && (
              <Form.Text className="text-danger fs-6">
                <AiOutlineExclamationCircle size="1rem" />{" "}
                <ErrorMessage errors={errors} name="name" />
              </Form.Text>
            )}
          </Form.Group>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mt-5">
              <Form.Label className="text-main">Email address:</Form.Label>
              <Form.Control
                type="email"
                className="border-main border-1 border"
                placeholder=""
                {...register("email", {
                  required: "Required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message:
                      "Please check that you have inputted your email correctly.",
                  },
                })}
              />
              {errors.email && (
                <Form.Text className="text-danger fs-6">
                  <AiOutlineExclamationCircle size="1rem" />{" "}
                  <ErrorMessage errors={errors} name="email" />
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group className="mt-5">
              <Form.Label className="text-main">Message</Form.Label>
              <Form.Control
                as="textarea"
                className="border-main border-1 border"
                rows={10}
                {...register("message", {
                  required: "Required",
                })}
              />
              {errors.message && (
                <Form.Text className="text-danger fs-6">
                  <AiOutlineExclamationCircle size="1rem" />{" "}
                  <ErrorMessage errors={errors} name="message" />
                </Form.Text>
              )}
            </Form.Group>
            <button
              className="roboto border-1 bg-main btn-main text-white mt-7"
              type="submit"
              disabled={isSubmitting || buttonText == "Message Sent"}
            >
              {buttonText}{" "}
              {isSubmitting && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </Form>
        </div>
        <div className="py-16"></div>
      </section>
    </Main>
  );
}
