import Main from "../layouts/Main";
import "./Ftd.css";
export default function Ftd() {
  return (
    <Main headerSecondary>
      <div id="ftd">
        <section className="hero">
          <h1 className="ftd-title">FTD</h1>
          <h2 className="ftd-sub-title">Flower online shop website</h2>
          <img src="/assets/img/FTD/FTD_Banner.png" alt="" className="" />
        </section>
        <section className="description">
          <div className="py-6"></div>
          <p className="px-3 px-0">
            FTD has been a leader in the floral industry for over a century. On
            this service, customers may order bouquets and send gifts to their
            loved ones.
          </p>
          <a
            href="https://xd.adobe.com/view/62699253-14fa-474a-81f0-2ab094463387-373a/"
            target="_blank"
            rel="noreferrer"
          >
            <button className="inter">View XD Prototype</button>
          </a>
        </section>
        <div className="py-6 py-sm-11"></div>
        <section className="main">
          <div className="header">
            <span className="inter">Overview</span>
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            Stakeholders Needs
          </h3>
          <p className="mx-auto px-3 px-sm-5 mt-6 mt-sm-0">
            This website’s design is dated and not particularly appealing to
            consumers nowadays. Additionally, this website’s design has a few
            issues that need to be addressed. anomy other things, the actions
            involved in making purchases are confusing.
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-8">
            Problem Statement
          </h3>
          <p className="mx-auto px-3 px-sm-5 mt-6 mt-sm-0">
            The main reason for this project is to redesign the outdated
            website, fix its problems, and focus on following the WCAG rules.
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-16 mt-sm-8">
            Goals
          </h3>
          <p className="mx-auto px-3 px-sm-5 mt-6 mt-sm-0">
            Redesigning the website and logo with a new user interface,
            resolving UX issues, and keeping an eye out for factors that make
            the website simple to use for users of all ability levels are all
            part of the process.
          </p>
          <div className="py-4 py-sm-9"></div>
          <div className="header">
            <span className="inter">Discovery</span>
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center ">
            Assessing the current site
          </h3>
          <p className="mx-auto discovery-list">
            <ul className="mt-9 mt-sm-0">
              <li>1. Error: 1.1.1 Non-text Content (Level A)</li>
              <div className="text-center">Missing Alternative Text</div>
              <div className="discover-image mx-auto">
                <img
                  src="/assets/img/FTD/Disscovery1_FTD.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <br />
              <li>2. Alerts: 2.4.3 Focus Order (Level A)</li>
              <div className="text-center">Tabindex</div>
              <div className="discover-image mx-auto">
                <img
                  src="/assets/img/FTD/Disscovery2_FTD.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <br />

              <li>3. Contrast Error: 1.4.3 Contrast (Minimum) (Level AA)</li>
              <div className="text-center">Very low contrast</div>
              <div className="discover-image mx-auto">
                <img
                  src="/assets/img/FTD/Disscovery3_FTD.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <br />

              <li>4. Error: 1.1.1 Non-text Content (Level A)</li>
              <div className="text-center">
                Spacer image missing alternative text
              </div>
              <div className="discover-image mx-auto">
                <img
                  src="/assets/img/FTD/Disscovery4_FTD.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <br />

              <li>5. Error: 3.1.1 Language of Page (Level A)</li>
              <div className="text-center">Language missing or invalid </div>
              <div className="discover-image mx-auto">
                <img
                  src="/assets/img/FTD/Disscovery5_FTD.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </ul>
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-sm-center px-10 px-sm-0">
            Let's see how I should solve the first error, for instance
          </h3>
          <p className="px-5 px-sm-0">
            Error 1: 1.1.1 Non-text Content (Level A) Missing Alternative Text
            <br />
            <br />
            An alt attribute is a brief description of an image that can be used
            to inform people of the image's existence if it cannot be displayed
            for a variety of reasons, including slow internet, sluggish system
            processing, extremely high image quality, or coding errors. To solve
            the first error, it needs to write a short descriptive phrase for
            the situation where the shopping cart image or icon is not displayed
            for any reason. In this case, the word "cart" is sufficient and
            appropriate. Websites like Amazon and Best Buy always include text
            along with the icon, moreover, providing the appropriate alt
            attribute for each visual element. That makes it simpler for the
            audience to comprehend how to utilize and what the icons are doing.
            Specifically, an icon like the shopping cart icon functions as a
            button and performs an action when clicked (seeing the contents of
            the shopping cart). In redesigning this website, in addition to
            observing the alt attribute for each image, I will put explanatory
            text next to each icon, especially for the more important icons.
          </p>
          <div className="py-4 py-sm-10"></div>
          <div className="header">
            <span className="inter">Usability Testing</span>
          </div>
          <div className="py-6"></div>
          <p className="px-4 px-sm-6">
            <div className="fs21">
              <b>Researcher:</b> Mahsa Ebrahim
              <br /> <b>Participant: </b>Hadi Torabi
              <br /> <b>User Goal:</b> To determine what improvements to the
              current website are required to achieve the goal of selling more
              types of flowers?
            </div>
          </p>
          <div className="py-0"></div>
          <p className="px-4 px-sm-6">
            <b>The Script:</b> <br />
            Introduction: First, I want to express my gratitude for taking the
            time to speak with us. This website is in the process of being
            redesigned. During this session, you will be asked to complete an
            activity on this website while thinking aloud. At the end of the
            day, we are working to improve the usability of this website, so we
            really need to hear your honest feedback.
            <br />
            <br />
            <b>Warm-Up Questions (3 min):</b>
            <br />
            <ol>
              <li>Would you rather be surprised or surprise other?</li>
              <li>
                Have you ever ordered flowers online or considered doing so?
              </li>
              <li>
                What are the advantages of ordering flowers online, in your
                opinion?
              </li>
            </ol>
            <b>The task:</b>
            <br />
            Task Scenario: Imagine that your wedding anniversary is nearby. You
            decided to purchase your spouse's favourite bouquet, deliver it to
            her at work and surprise her.
            <br />
            Show me how you would order it.
            <br />
            <br />
            <b>Wrap Up Questions (2-3) and closing remarks:</b>
            Thank you so much for your time and consideration. Before we end,
            let's talk about:
            <br />
            <br />
            <ol>
              <li>
                <b>
                  What is the worst thing that could happen while ordering
                  flowers online?
                </b>
                <br />- The worst thing that may happen when buying flowers
                online is an inconsistency between the final order and the
                product photo on the website. Flowers are usually purchased as
                gifts for others. We won't be able to see our order until it's
                delivered and make sure it's at least somewhat comparable to the
                beautiful image we saw on the website. We ordered flowers based
                on that lovely image. If our purchase does not meet the
                recipient's expectations for quality and attractiveness, we will
                be embarrassed and ashamed.
              </li>
              <br />
              <li>
                <b>What feature do you think was missing on this website?</b>
                <br />- The ability to customize the flowers, such as selecting
                a different color range of flowers or the ribbon color and vase
                type. In my opinion, if those were included, the user would be
                able to apply his taste too.
              </li>
            </ol>
          </p>
          <div className="py-2"></div>
          <div className="header">
            <span className="inter">PLANING & IDEATE</span>
          </div>
          <div className="py-5 py-sm-0"></div>
          <p className="mx-8 mx-sm-auto">
            We documented our primary findings and issues resulting from our
            usability testing, that relate to user's likes or dislikes and then
            brainstormed ways to group our findings into related categories. The
            facilitator led the creation of category headings for these groups.
            <br />
            <br />
            Next, we sorted our findings (which were written on sticky notes)
            into their respective categories. We discussed the findings with the
            group and identified the key problems.
            <br />
            <br />
            To generate potential solutions, we followed ideation techniques and
            the facilitator led a discussion to review the ideas that the group
            had generated. During the discussion, the facilitator went through
            each sticky note, acknowledging it and opening it up to the group
            for further discussion.
          </p>
          <div className="sticky-image mx-auto">
            <img
              src="/assets/img/FTD/Sticky_FTD.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="error-image mx-auto">
            <img
              src="/assets/img/FTD/ErrorFinding_FTD.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-7 py-sm-10"></div>
          <div className="header">
            <span className="inter">DESIGN</span>
          </div>
          <h3 className="fs18 fssm40 fw-bold inter text-center ">
            Brand Guide
          </h3>
          <div className="brand-image mx-auto">
            <img
              src="/assets/img/FTD/BrandGuid_FTD.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <h3 className="fs18 fssm40 fw-bold inter text-center ">
            WCAG Success Criteria
          </h3>
          <div className="wcag-image mx-auto">
            <img
              src="/assets/img/FTD/WCAG_FTD.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-6 py-sm-9"></div>
          <div className="header">
            <span className="inter">DELIVER & LAUNCH</span>
          </div>
          <h3 className="fs18 fssm40 fw-bold inter text-center mt-16">
            High-fidelity wireframe
          </h3>
          <div className="fidelity-image mx-auto">
            <img
              src="/assets/img/FTD/HighFi_FTD.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="fidelity-video mx-auto">
            <video
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
              className="w-100"
            >
              <source src={"/assets/video/ftd.mp4"} type="video/mp4" />
            </video>
          </div>
          <div className="py-6 py-sm-13"></div>
          <div className="header">
            <span className="inter">REFLECTIONS</span>
          </div>
          <h3 className="fs18 fssm40 fw-bold inter text-center mt-12 mt-sm-16">
            What did I learn?
          </h3>
          <p className="px-4 px-sm-6 mt-6 mt-sm-0">
            As a UI/UX designer, redesigning FTD website and applying the Web
            Content Accessibility Guidelines (WCAG) rules to it has taught me
            several valuable lessons. Through this experience, I have learned:
            <br />
            <br />
            <ul className="ms-1">
              <li>
                The importance of considering accessibility from the beginning:
                As a designer, it's crucial to design with accessibility in
                mind. By considering the needs of users with disabilities, I can
                create more inclusive and user-friendly websites.
              </li>
              <br />
              <li>
                The impact of color contrast on accessibility: When designing
                for accessibility, I need to ensure that there is sufficient
                contrast between text and background colors. This helps users
                with visual impairments to read and navigate the website more
                easily.
              </li>
              <br />
              <li>
                The need for clear and descriptive labels: To make a website
                more accessible, I need to ensure that all labels and
                descriptions are clear and descriptive. This helps users with
                cognitive or language impairments to understand the content more
                easily.
              </li>
              <br />
              <li>
                The value of user testing: After redesigning the website and
                applying the WCAG rules, it's important to test the website with
                users who have disabilities. This can provide valuable feedback
                on how well the website meets their needs and where there is
                room for improvement.
              </li>
              <br />
              <li>
                The benefits of designing for accessibility: Designing for
                accessibility not only benefits users with disabilities but also
                improves the overall user experience for everyone. By
                considering accessibility in the design process, I can create
                websites that are easier to use, more intuitive, and more
                engaging for all users.
              </li>
            </ul>
          </p>
          <div className="py-15"></div>
        </section>
      </div>
    </Main>
  );
}
