import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import PlanDo from "../pages/PlanDo";
import Onra from "../pages/Onra";
import AppleDry from "../pages/AppleDry";
import Ecycle from "../pages/Ecycle";
import Ftd from "../pages/Ftd";
import Other from "../pages/Other";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Blog from "../pages/Blog";
import BlogPost1 from "../pages/BlogPost1";
import BlogPost2 from "../pages/BlogPost2";
import BlogPost3 from "../pages/BlogPost3";
import BlogPost4 from "../pages/BlogPost4";
import BlogPost5 from "../pages/BlogPost5";
import SibBakery from "../pages/SibBakery";
export default function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route exact path="/blogpost_5" element={<BlogPost5 />} />
        <Route exact path="/blogpost_4" element={<BlogPost4 />} />
        <Route exact path="/blogpost_3" element={<BlogPost3 />} />
        <Route exact path="/blogpost_2" element={<BlogPost2 />} />
        <Route exact path="/blogpost_1" element={<BlogPost1 />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/other" element={<Other />} />
        <Route exact path="/ftd" element={<Ftd />} />
        <Route exact path="/ecycle" element={<Ecycle />} />
        <Route exact path="/apple-dry" element={<AppleDry />} />
        <Route exact path="/plando" element={<PlanDo />} />
        <Route exact path="/sib_bakery" element={<SibBakery />} />
        <Route exact path="/onra" element={<Onra />} />
        <Route exact path="/" element={<HomePage />} />
        <Route path="*" element={<HomePage />} />
      </Routes>
    </Router>
  );
}
