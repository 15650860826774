import Main from "../layouts/Main";
import "./Ecycle.css";
export default function Ecycle() {
  return (
    <Main headerSecondary>
      <div id="ecycle">
        <section className="hero">
          <video
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            className="d-none d-sm-block"
          >
            <source src={"/assets/video/ecycle.mp4"} type="video/mp4" />
          </video>
          <h1 className="ecycle-title">eCycle</h1>
          <h2 className="ecycle-sub-title">e-bike sharing system</h2>
        </section>
        <section className="description">
          <div className="py-6"></div>
          <p className="px-4">
            The Canadian born company, eCycle is an e-bike-sharing system that
            provides Electric bikes to people to use within various city
            locations situated across Canada. eCycle consists of multiple
            interconnected stations whereby people can exchange e-bikes to rent,
            based upon a given time frame. The ‘docking’ stations, which are
            interspersed across the city core, provide convenient drop off and
            pick up locations to those with few mobility alternatives.{" "}
          </p>
          <a
            href="https://xd.adobe.com/view/47844f35-bbd7-4d22-a877-6ae7a9393dff-12d7/"
            target="_blank"
            rel="noreferrer"
          >
            <button className="inter">View XD Prototype</button>
          </a>
        </section>
        <div className="py-7 py-sm-11"></div>
        <section className="main">
          <div className="header">
            <span className="inter">Overview</span>
          </div>
          <div className="overview-image mx-auto">
            <img
              src="/assets/img/eCycle/Overview_eCycle.png"
              alt=""
              className="img-fluid d-none d-sm-block"
            />
            <img
              src="/assets/img/eCycle/Overview_eCycle_Mobile.png"
              alt=""
              className="img-fluid d-block d-sm-none"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            Stakeholders Needs
          </h3>
          <p className="mx-auto px-3 px-sm-5">
            Up until now, eCycle users have only been able to locate available
            bikes and rent from the station kiosk.
            <br />
            <br />
            The stakeholders would like an App created for the eCycle
            e-bike-sharing system so users can:
            <br />
            <br />
            <ul className="ms-0">
              <li>Sign up/log in order to track and save user data</li>
              <li>Be informed on ‘How to rent an eCycle’. (onboarding)</li>
              <li>
                Be informed of the benefits of using e-bikes. (onboarding)
              </li>
              <li>
                Be informed of the safety rules and regulations (e.g., helmets
                and road safety) of riding an e-bike. (onboarding)
              </li>
              <li>
                Locate eCycle stations based upon a user's location on a map and
                view on a list
              </li>
              <li>
                Be informed on how many e-bikes are available at any given
                station on a map view on a list.
              </li>
              <li>Rent an available e-bike at one of the stations.</li>
              <li>View a Ride History</li>
              <li>Search for a station</li>
              <li>Unlock the bike using the app</li>
              <li>
                Choose the length of time the user will rent the e-bike for a
                specified price:
              </li>
            </ul>
            <h5 className="text-center">30 mins = $3.00</h5>
            <h5 className="text-center">1 hour = $5.00</h5>
            <h5 className="text-center">24 hours = $10.00</h5>
            <div className="py-sm-8"></div>
            <ul className="ms-0">
              <li>Pay for the rental</li>
              <li>
                Receive a 10-digit code from the App to unlock a bike. You have
                one hour to get to pick up your bike. The code unlocks the bike
                and starts the user's ride. The user returns the bike to any one
                of the other stations. This will end the ride.
              </li>
            </ul>
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-13">
            Problem Statement
          </h3>
          <p className="mx-auto px-7 px-sm-5">
            So far, Cycle users have only been able to find available bikes and
            rent them from the station kiosk. By the way, users can not use the
            features such as remaining time, finding other stations or a place
            to park the bike, reserve a bike, and other features that an
            application can provide users.
          </p>
          <div className="py-8 py-sm-0"></div>

          <h3 className="fs18 fssm32 fw-bold inter text-center mt-3">Goal</h3>
          <p className="mx-auto px-7 px-sm-5">
            To improve and facilitate the process of renting e-bikes,
            stakeholders of Cycle have suggested the development of an
            application so that users can have a more enjoyable experience of
            using e-bikes and address their previous concerns. Including
            features such as showing the remaining time, the number of available
            e-bikes and docks at stations, and the possibility of booking bikes
            in advance, are among the solutions that intend to encourage users
            to use more of these facilities.
          </p>
          <div className="py-7 py-sm-4"></div>
          <div className="header">
            <span className="inter">The Process</span>
          </div>
          <div className="py-6"></div>
          <p className="text-center text-sm-start mx-auto px-4 px-sm-16">
            My two months design sprint was divided into five stages: <br /> It
            should be noted that iteration is essential at every stage.
          </p>
          <div className="process-image mx-auto">
            <img
              src="/assets/img/eCycle/Process_eCycle.png"
              alt=""
              className="img-fluid d-none d-sm-block"
            />
            <img
              src="/assets/img/eCycle/Process_eCycle_Mobile.png"
              alt=""
              className="img-fluid d-block d-sm-none"
            />
          </div>
          <div className="py-6 py-sm-9"></div>
          <div className="header">
            <span className="inter">Empathize & Define</span>
          </div>
          <div className="py-6"></div>
          <p className="mx-auto px-4 px-sm-5">
            I conducted research to better understand the state of the market
            and the needs of my target audience before starting to solve the
            challenges.
            <br />
            <br />
            As a result, the study began with three phases:
            <br />
            <br />
            <ol>
              <li>User Research</li>
              <li>Persona </li>
              <li>Storyboard </li>
            </ol>
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-13">
            User Research
          </h3>
          <p className="px-4 px-sm-5">
            We conducted field research, creating a persona, a storyboard and a
            competitive analysis to gain an understanding more about the users.
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-16">
            Persona
          </h3>
          <div className="persona-image mx-auto">
            <img
              src="/assets/img/eCycle/Persona_eCycle.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-16">
            Storyboard
          </h3>
          <div className="story-image mx-auto">
            <img
              src="/assets/img/eCycle/StoryBoard_eCycle.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-11 py-sm-9"></div>
          <div className="header">
            <span className="inter">PLANING & IDEATE</span>
          </div>
          <div className="py-6"></div>
          <p className="px-3 px-sm-5">
            I started to evaluate the issue in order to identify potential
            remedies after carefully evaluating the circumstance and
            establishing the appropriate goals. I checked the method of solving
            the problem by other competitors and in this way I found out their
            positive and negative points.{" "}
          </p>

          <div className="py-7 py-sm-0"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-4">
            Competitive Analysis
          </h3>
          <p className="mt-7 mt-sm-0 px-3 px-sm-5">
            I conduct a competitive analysis in 4 steps:
            <br />
            <br className="d-sm-none " />
            <ol>
              <li>Identify our competitors</li>
              <li>Gather information about our competitors</li>
              <li>Analyze our competitors’ strengths and weaknesses</li>
              <li>Determine our competitive advantage</li>
            </ol>
          </p>
          <div className="analyze-image mx-auto">
            <img
              src="/assets/img/eCycle/Competitive_Analysis_eCycle.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-3 py-sm-0"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center ">User Flow</h3>
          <p className="px-3 px-sm-5">
            I started creating the user flows to take user demands into account.
            <br />
            A consumer-grade UX and UI are built on this user flow that is
            focused on the human being.
            <br />
            <br />I use user flow to clarify the user procedure. Evaluation and
            optimization of the user experience are aided by information
            structuring and user flow development.
          </p>
          <div className="flow-image mx-auto">
            <img
              src="/assets/img/eCycle/UserFlow_eCycle.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-6 py-sm-10"></div>
          <div className="header">
            <span className="inter">PROTOTYPE & DESIGN</span>
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center ">
            Brand Guide
          </h3>
          <div className="brand-image mx-auto">
            <img
              src="/assets/img/eCycle/Brand_Guid1_eCycle.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="brand2-image mx-auto">
            <img
              src="/assets/img/eCycle/Brand_Guid2__eCycle.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-16">
            Low-fidelity wireframe
          </h3>
          <div className="fidelity-image mx-auto">
            <img
              src="/assets/img/eCycle/LowFi_eCycle.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-8 py-sm-10"></div>
          <div className="header">
            <span className="inter">DELIVER & LAUNCH</span>
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-16">
            High-fidelity wireframe
          </h3>
          <div className="fidelity-image mx-auto">
            <img
              src="/assets/img/eCycle/HighFi_eCycle.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-6 py-sm-11"></div>
          <div className="header">
            <span className="inter">REFLECTIONS</span>
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-16">
            What Did I Learn?
          </h3>
          <p className="px-3 px-sm-5">
            The end of a UX/UI design project is an opportunity for reflection,
            learning, and growth. It provides us with the information we need to
            make informed design decisions and improve the user experience in
            future projects.
            <br />
            <br />
            <br />
            <ul className="ms-1">
              <li>
                User insights: I gained a deeper understanding of the target
                audience and their needs, behaviours, and preferences. This
                informed my future design decisions and ensured that my designs
                were more user-centered.
              </li>
              <br />
              <li>
                Design process: I reflected on my design process and identified
                areas where I could improve. This included changes to the way I
                approached problem-solving, the tools I used, and my
                collaboration with other team members.
              </li>
              <br />
              <li>
                Design decisions: By analyzing the end-result of the project, I
                evaluated the design decisions I made and determined what worked
                well and what I could have done differently.
              </li>
              <br />
              <li>
                Feedback: User testing and feedback provided valuable insights
                into what worked well and what didn't. This informed my future
                design decisions and ensured that my designs were more
                effective.
              </li>
            </ul>
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-14">
            What can we do next?
          </h3>
          <p className="px-5">
            I want to make the interface design component better.
          </p>
          <div className="py-12"></div>
        </section>
      </div>
    </Main>
  );
}
