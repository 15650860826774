import OffcanvasX from "react-bootstrap/Offcanvas";
import { BsBehance, BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";
import { NavLink, useNavigate } from "react-router-dom";

export default function Offcanvas(props) {
  const style = {
    social: {
      display: "inline-block",
      borderRadius: "50%",
      backgroundColor: "var(--bs-main)",
      color: "white",
      width: "34px",
      height: "34px",
    },
  };
  return (
    <OffcanvasX
      show={props?.show}
      onHide={props?.handleClose}
      placement={props?.placement}
      id="menu-offcanvas"
    >
      <OffcanvasX.Header>
        <img src="/assets/img/Home/ME_Logo.png" alt="LOGO" className="logo" />
        <IoCloseOutline
          style={{ width: "35px", height: "35px", color: "var(--bs-main)" }}
          onClick={props?.handleClose}
          role="button"
        />
      </OffcanvasX.Header>
      <OffcanvasX.Body>
        <div className="navbar-list">
          <NavLink to="/">Home</NavLink>
          <NavLink to="/sib_bakery" className="ms-4 d-block d-sm-none">
            &#8226; Sib-bakery
          </NavLink>
          <NavLink to="/plando" className="ms-4 d-block d-sm-none">
            &#8226; PlanDo
          </NavLink>
          <NavLink to="/apple-dry" className="ms-4 d-block d-sm-none">
            &#8226; Apple Dry
          </NavLink>
          <NavLink to="/ecycle" className="ms-4 d-block d-sm-none">
            &#8226; eCycle
          </NavLink>
          <NavLink to="/ftd" className="ms-4 d-block d-sm-none">
            &#8226; FTD
          </NavLink>
          <NavLink to="/other" className="ms-4 d-block d-sm-none">
            &#8226; My Other Works
          </NavLink>
          <a
            href="/assets/Mahsa_Ebrahim_CV.docx"
            target="_blank"
            rel="noreferrer"
            className="d-none d-sm-block"
            download
          >
            Resume
          </a>{" "}
          <NavLink to="/about">About</NavLink>
          <NavLink to="/contact">Contact</NavLink>
          {/* <NavLink className="d-none d-sm-block" to="/blog">
            Blog
          </NavLink> */}
          <a
            className="mx-3 d-block d-sm-none"
            href="https://www.instagram.com/moonsa_art/?hl=en"
            target="_blank"
            rel="noreferrer"
            style={style.social}
          >
            <BsInstagram style={{ top: 0, margin: "7px" }} />
          </a>
          <a
            className="mx-3 d-block d-sm-none"
            href="https://www.linkedin.com/in/mahsa-hm-ebrahim/"
            target="_blank"
            rel="noreferrer"
            style={style.social}
          >
            <FaLinkedinIn style={{ top: 0, margin: "7px" }} />
          </a>
          <a
            className="mx-3 d-block d-sm-none"
            href="https://www.behance.net/mahsaeb"
            target="_blank"
            rel="noreferrer"
            style={style.social}
          >
            <BsBehance style={{ top: 0, margin: "7px" }} />
          </a>
        </div>
      </OffcanvasX.Body>
    </OffcanvasX>
  );
}
