import { Link } from "react-router-dom";
import "./Footer.css";
export default function Footer() {
  return (
    <div id="footer" className="">
      <div className="navbar-list mb-8 d-none d-sm-block">
        <Link to="/">Home</Link>
        <a
          href="/assets/Mahsa_Ebrahim_CV.docx"
          target="_blank"
          rel="noreferrer"
          className=""
          download
        >
          Resume
        </a>
        <Link to="/about">About</Link>
        <Link to="/contact">Contact</Link>
        {/* <Link to="/blog">Blog</Link> */}
      </div>

      <span className="fs16 text-white inter mb-8">
        Designed by Mahsa Ebrahim - {new Date().getFullYear()}
      </span>
      {/* <img src="/assets/img/Home/footer-bg.svg" alt="" /> */}
    </div>
  );
}
