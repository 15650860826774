import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./LoginModal.css";
export default function LoginModal({ onClose, show }) {
  const [error, setError] = useState(false);
  const handleLogin = () => {
    setTimeout(() => {
      setError(true);
      setTimeout(() => {
        onClose();
      }, 3000);
    }, 2000);
  };
  return (
    <Modal
      show={show}
      onHide={onClose}
      size="md"
      centered
      contentClassName="bg-white rounded-3"
    >
      <Modal.Header closeButton>
        <Modal.Title>Enter Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" className="border-primary border" />
            {error && <span className="text-danger">Invalid Credential</span>}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleLogin}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
