import "./Header.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { IoMdMenu } from "react-icons/io";
import Offcanvas from "../Header2/OffCanvas";

export default function Header(props) {
  let activeClassName = "active";
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // console.log(props?.variant);
  return (
    <>
      <div id="header" className="">
        <Link to="/">
          <img src="/assets/img/Home/ME_Logo.png" alt="LOGO" className="logo" />
        </Link>
        <div className="navbar-list d-none d-sm-block">
          <NavLink to="/">Home</NavLink>
          <a
            href="/assets/Mahsa_Ebrahim_CV.docx"
            target="_blank"
            rel="noreferrer"
            className=""
            download
          >
            Resume
          </a>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/contact">Contact</NavLink>
          {/* <NavLink to="/blog">Blog</NavLink> */}
        </div>
        <div className="navbar-list-mobile d-block d-sm-none">
          <div
            className="menu-toggle"
            role="button"
            onClick={handleShow}
            style={{
              color: props?.variant == "main" ? "var(--bs-main)" : "white",
            }}
          >
            <IoMdMenu
              style={{ width: "35px", height: "35px", fill: "var(--bs-main)" }}
            />
          </div>
        </div>
      </div>
      <div id="header-height" className="header-height"></div>
      <Offcanvas show={show} handleClose={handleClose} placement="end" />
    </>
  );
}
