import { Button } from "bootstrap";
import { matchRoutes, useLocation, useNavigate } from "react-router";
import "./Footer.css";
import LoginModal from "../../pages/LoginModal";
import useModal from "../../hooks/useModal/useModal";
export default function Footer() {
  const navigate = useNavigate();
  const location = useLocation();
  const [showLoginModal, handleCloseLoginModal, handleShowLoginModal] =
    useModal();

  return (
    <div id="footer2" className="">
      <img
        src="/assets/img/footer-bg.svg"
        alt=""
        className="footer-bg position-absolute"
      />
      <span className="d-block d-sm-none fs16 inter mx-auto pt-13 text-center text-white w-100">
        Designed by Mahsa Ebrahim - {new Date().getFullYear()}
      </span>

      <div className="d-none d-sm-flex flex-column flex-md-row align-items-center footer-container justify-content-between">
        <div className="logo-section">
          <div className="d-flex align-items-end">
            <img
              src="/assets/img/footer-logo.png"
              alt=""
              className="footer-logo"
              role="button"
              onClick={() => navigate("/")}
            />
            <div className="ms-4">
              <span className="text-white fs20 fw-bold">Mahsa Ebrahim</span>
              <br />
              <span className="text-white fs16 fw-bold p-0">
                UX/UI Designer
              </span>
            </div>
          </div>
          <div className="d-flex mt-10">
            <a
              href="/assets/Mahsa_Ebrahim_CV.docx"
              target="_blank"
              rel="noreferrer"
              download
              type="button"
              className="btn button"
            >
              Resume
            </a>
            <button
              type="button"
              className="btn "
              onClick={() => navigate("/contact")}
            >
              Contact
            </button>
          </div>
        </div>
        <div className="navigator-section">
          <div className="row">
            <span className="text-white fs18 fw-bold text-capitalize mb-4">
              See more of my works:
            </span>
            <button
              type="button"
              className="btn "
              role="button"
              onClick={() => handleShowLoginModal()}
            >
              Sib Bakery
            </button>
            {location?.pathname.toLowerCase() == "/plando" || (
              <button
                type="button"
                className="btn "
                role="button"
                onClick={() => navigate("/plando")}
              >
                PlanDo
              </button>
            )}
            {location?.pathname.toLowerCase() == "/ecycle" || (
              <button
                type="button"
                className="btn "
                role="button"
                onClick={() => navigate("/ecycle")}
              >
                eCycle
              </button>
            )}
            {location?.pathname.toLowerCase() == "/ftd" || (
              <button
                type="button"
                className="btn "
                role="button"
                onClick={() => navigate("/ftd")}
              >
                FTD
              </button>
            )}
            {location?.pathname.toLowerCase() == "/other" || (
              <button
                type="button"
                className="btn "
                role="button"
                onClick={() => navigate("/other")}
              >
                Other Works
              </button>
            )}
          </div>
        </div>
      </div>
      <LoginModal show={showLoginModal} onClose={handleCloseLoginModal} />
    </div>
  );
}
