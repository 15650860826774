import Main from "../layouts/Main";
import { BsArrowRight } from "react-icons/bs";
import "./BlogPost.css";
import { Link, useLocation } from "react-router-dom";
export default function BlogPost2() {
  const location = useLocation();

  return (
    <Main>
      <div id="blog-post">
        <div className="py-15"></div>
        <section className="row">
          <div className="col-12 col-lg-7 first">
            <h1 className="fs40 fw-bold text-main">
              The Impact of AI on UX Design:{" "}
            </h1>
            <img
              src="/assets/img/Blog_2/Photo_Blog_2.png"
              alt=""
              className="img-fluid mt-8"
            />
            <p className="mt-8">
              Artificial Intelligence (AI) is having a significant impact on UX
              design, and it has the potential to revolutionize the way that
              user experiences are designed and delivered. Here are some ways AI
              is affecting UX design:
              <br />
              <br />
              <ol>
                <li>
                  Personalization: AI can help designers create personalized
                  user experiences by analyzing user data and adjusting the
                  interface based on individual preferences and behaviour
                  patterns.
                </li>
                <br />
                <li>
                  Predictive Design: AI can be used to anticipate user needs and
                  predict their behaviour, allowing designers to create
                  experiences that are more intuitive and efficient.
                </li>
                <br />
                <li>
                  Chatbots and Voice Interfaces: AI-powered chatbots and voice
                  interfaces can provide users with quick and convenient access
                  to information and services, reducing friction in the user
                  experience.
                </li>
                <br />
                <li>
                  Accessibility: AI can help improve accessibility for users
                  with disabilities by automating the process of testing for
                  accessibility and providing suggestions for improvement.
                </li>
                <br />
                <li>
                  Optimization: AI can be used to optimize the user experience
                  by analyzing user behaviour and making real-time adjustments
                  to improve user engagement and satisfaction.
                </li>
                <br />
                <li>
                  Content Generation: AI can be used to generate content, such
                  as product descriptions or personalized recommendations,
                  saving designers time and effort.
                </li>
              </ol>
            </p>
            <br />
            <p>
              However, it's important to note that AI should be used as a tool
              to enhance the user experience, not as a replacement for human
              design intuition and empathy. AI should also be used ethically,
              respecting user privacy and avoiding biased or unfair outcomes.
              Ultimately, the role of AI in UX design is to augment the
              designer's ability to create meaningful and enjoyable user
              experiences.
            </p>
          </div>
          <div className="d-none d-lg-block col-lg-5 second">
            <div className="more-topics">
              <h4 className="fs20 mb-6">More Topics:</h4>
              {location?.pathname.toLowerCase() == "/blogpost_2" || (
                <Link to="/blogpost_2">
                  <div className="topic">
                    <span className="title">
                      The Impact of AI on UX Design.
                    </span>
                    <img
                      src="/assets/img/Blog_1/Small1.png"
                      alt=""
                      className="topic-img"
                    />
                  </div>
                </Link>
              )}
              {location?.pathname.toLowerCase() == "/blogpost_3" || (
                <Link to="/blogpost_3">
                  <div className="topic">
                    <span className="title">
                      Examples of using AI in UX design projects.
                    </span>
                    <img
                      src="/assets/img/Blog_1/Small2.png"
                      alt=""
                      className="topic-img"
                    />
                  </div>
                </Link>
              )}

              {location?.pathname.toLowerCase() == "/blogpost_4" || (
                <Link to="/blogpost_4">
                  <div className="topic">
                    <span className="title">
                      What kind of influence a UX designer can have on a
                      project?
                    </span>
                    <img
                      src="/assets/img/Blog_1/Small3.png"
                      alt=""
                      className="topic-img"
                    />
                  </div>
                </Link>
              )}

              {location?.pathname.toLowerCase() == "/blogpost_5" || (
                <Link to="/blogpost_5">
                  <div className="topic">
                    <span className="title">
                      How to be a professional UX Designer
                    </span>
                    <img
                      src="/assets/img/Blog_1/Small40.png"
                      alt=""
                      className="topic-img"
                    />
                  </div>
                </Link>
              )}

              {location?.pathname.toLowerCase() == "/blogpost_1" || (
                <Link to="/blogpost_1">
                  <div className="topic">
                    <span className="title">
                      How can you use chat GPT for UX research?
                    </span>
                    <img
                      src="/assets/img/Blog_1/Small5.png"
                      alt=""
                      className="topic-img"
                    />
                  </div>
                </Link>
              )}
            </div>
          </div>{" "}
          <div className="py-16"></div>
        </section>
      </div>
    </Main>
  );
}
