import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

export function QueryProvider(props) {
  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
    </QueryClientProvider>
  );
}
export { queryClient };
