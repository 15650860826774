import Main from "../layouts/Main";
import { BsArrowRight } from "react-icons/bs";
import "./Blog.css";
import { useNavigate } from "react-router";
export default function Blog() {
  const navigate = useNavigate();
  return (
    <Main>
      <div id="blog">
        <div className="py-15"></div>
        <section className="row">
          <div className="col-12 col-lg-7 first">
            <h1 className="fs24 fw-bold">
              Here, I discuss my experiences, tutorials, and writings about
              UX/UI design.
            </h1>
            <div className="py-3"></div>
            <div className="posts">
              <div className="post">
                <div className="left">
                  <div className="date">Feb 7 2023</div>
                  <div className="duration">4 min read</div>
                </div>
                <div
                  className="right"
                  onClick={() => navigate("/blogpost_1")}
                  role="button"
                >
                  <div className="title">
                    How can you use chatGPT for UX research?
                  </div>
                  <div className="sub-title">
                    How to be a professional UX designer
                  </div>
                  <div className="read-more">
                    Read more <BsArrowRight />
                  </div>
                </div>
              </div>
              <div className="post">
                <div className="left">
                  <div className="date">Feb 2 2023</div>
                  <div className="duration">2 min read</div>
                </div>
                <div
                  className="right"
                  onClick={() => navigate("/blogpost_3")}
                  role="button"
                >
                  <div className="title">
                    Examples of using AI in UX design projects
                  </div>
                  <div className="sub-title">
                    How to be a professional UX designer
                  </div>
                  <div className="read-more">
                    Read more <BsArrowRight />
                  </div>
                </div>
              </div>
              <div className="post">
                <div className="left">
                  <div className="date">Jan 17 2023</div>
                  <div className="duration">4 min read</div>
                </div>
                <div
                  className="right"
                  onClick={() => navigate("/blogpost_2")}
                  role="button"
                >
                  <div className="title">The Impact of AI on UX Design:</div>
                  <div className="sub-title">
                    How to be a professional UX designer
                  </div>
                  <div className="read-more">
                    Read more <BsArrowRight />
                  </div>
                </div>
              </div>
              <div className="post">
                <div className="left">
                  <div className="date">Jan 11 2023</div>
                  <div className="duration">2 min read</div>
                </div>
                <div
                  className="right"
                  onClick={() => navigate("/blogpost_4")}
                  role="button"
                >
                  <div className="title">
                    What kind of influence a UX designer can have on a project?
                  </div>
                  <div className="sub-title">
                    How to be a professional UX designer
                  </div>
                  <div className="read-more">
                    Read more <BsArrowRight />
                  </div>
                </div>
              </div>
              <div className="post">
                <div className="left">
                  <div className="date">Dec 4 2022</div>
                  <div className="duration">2 min read</div>
                </div>
                <div
                  className="right"
                  onClick={() => navigate("/blogpost_5")}
                  role="button"
                >
                  <div className="title">
                    How to be a professional UX designer?
                  </div>
                  <div className="sub-title">
                    How to be a professional UX designer
                  </div>
                  <div className="read-more">
                    Read more <BsArrowRight />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-lg-block col-lg-5 second">
            <img
              src="/assets/img/Blog/Photo_Blog.png"
              alt="portfolio face"
              className="img-fluid"
            />
          </div>
          <div className="py-16"></div>
        </section>
      </div>
    </Main>
  );
}
