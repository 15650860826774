import Main from "../layouts/Main";
import { BsArrowRight } from "react-icons/bs";
import "./BlogPost.css";
import { Link, useLocation } from "react-router-dom";
export default function BlogPost4() {
  const location = useLocation();

  return (
    <Main>
      <div id="blog-post">
        <div className="py-15"></div>
        <section className="row">
          <div className="col-12 col-lg-7 first">
            <h1 className="fs40 fw-bold text-main">
              What kind of influence a UX designer can have on a project?
            </h1>
            <img
              src="/assets/img/Blog_4/Photo_Blog_4.png"
              alt=""
              className="img-fluid mt-8"
            />

            <p className="mt-8">
              A UX designer can have a significant impact on a project, as they
              play a critical role in defining and shaping the user experience.
              The extent to which a UX designer can impact a project depends on
              several factors, including:
              <br />
              <br />
              <ol>
                <li>
                  Project scope: The size and complexity of a project will
                  affect the UX designer's ability to influence the final
                  outcome. For smaller projects, the UX designer may have more
                  control, while larger projects may involve more stakeholders
                  and require more collaboration.
                </li>
                <br />
                <li>
                  Role in the design process: The UX designer's role in the
                  design process can also have a significant impact. A UX
                  designer who is involved from the beginning of the project
                  will have a greater opportunity to shape the direction of the
                  project and have a more significant impact.
                </li>
                <br />
                <li>
                  Collaboration with other stakeholders: The level of
                  collaboration between the UX designer and other stakeholders,
                  such as product managers, developers, and executives, will
                  also affect the UX designer's impact. When there is open and
                  effective communication, the UX designer can more effectively
                  influence the design decisions and outcomes.
                </li>
                <br />
                <li>
                  Quality of the UX design process: The quality of the UX design
                  process, including research, prototyping, and testing, can
                  also impact the UX designer's impact on the project. A
                  well-designed and well-executed UX process will help ensure
                  that the final product meets the needs of the users and the
                  business.
                </li>
              </ol>
            </p>
            <br />
            <p>
              Overall, the impact a UX designer can have on a project will
              depend on several factors, but with the right approach and
              support, they can play a key role in creating successful and
              user-centered products.
            </p>
          </div>
          <div className="d-none d-lg-block col-lg-5 second">
            <div className="more-topics">
              <h4 className="fs20 mb-6">More Topics:</h4>
              {location?.pathname.toLowerCase() == "/blogpost_2" || (
                <Link to="/blogpost_2">
                  <div className="topic">
                    <span className="title">
                      The Impact of AI on UX Design.
                    </span>
                    <img
                      src="/assets/img/Blog_1/Small1.png"
                      alt=""
                      className="topic-img"
                    />
                  </div>
                </Link>
              )}
              {location?.pathname.toLowerCase() == "/blogpost_3" || (
                <Link to="/blogpost_3">
                  <div className="topic">
                    <span className="title">
                      Examples of using AI in UX design projects.
                    </span>
                    <img
                      src="/assets/img/Blog_1/Small2.png"
                      alt=""
                      className="topic-img"
                    />
                  </div>
                </Link>
              )}

              {location?.pathname.toLowerCase() == "/blogpost_4" || (
                <Link to="/blogpost_4">
                  <div className="topic">
                    <span className="title">
                      What kind of influence a UX designer can have on a
                      project?
                    </span>
                    <img
                      src="/assets/img/Blog_1/Small3.png"
                      alt=""
                      className="topic-img"
                    />
                  </div>
                </Link>
              )}

              {location?.pathname.toLowerCase() == "/blogpost_5" || (
                <Link to="/blogpost_5">
                  <div className="topic">
                    <span className="title">
                      How to be a professional UX Designer
                    </span>
                    <img
                      src="/assets/img/Blog_1/Small40.png"
                      alt=""
                      className="topic-img"
                    />
                  </div>
                </Link>
              )}

              {location?.pathname.toLowerCase() == "/blogpost_1" || (
                <Link to="/blogpost_1">
                  <div className="topic">
                    <span className="title">
                      How can you use chat GPT for UX research?
                    </span>
                    <img
                      src="/assets/img/Blog_1/Small5.png"
                      alt=""
                      className="topic-img"
                    />
                  </div>
                </Link>
              )}
            </div>
          </div>{" "}
          <div className="py-16"></div>
        </section>
      </div>
    </Main>
  );
}
