import Main from "../layouts/Main";
import { BsArrowRight } from "react-icons/bs";
import "./BlogPost.css";
import { Link, useLocation } from "react-router-dom";
export default function BlogPost5() {
  const location = useLocation();

  return (
    <Main>
      <div id="blog-post">
        <div className="py-15"></div>
        <section className="row">
          <div className="col-12 col-lg-7 first">
            <h1 className="fs40 fw-bold text-main">
              How to be a professional Ux designer?
            </h1>

            <img
              src="/assets/img/Blog_5/Photo_Blog_5.png"
              alt=""
              className="img-fluid mt-8"
            />

            <p className="mt-8">
              Becoming a professional UX designer requires both practical
              experience and a strong understanding of design principles and
              methodology. Here are some steps you can follow to become a
              professional UX designer:
              <br />
              <br />
              <ol>
                <li>
                  Study UX design: Start by learning about UX design principles,
                  methodologies, and practices. Read books, articles, and blogs
                  on UX design, and familiarize yourself with popular design
                  tools such as Sketch, Figma, and InVision.
                </li>
                <br />
                <li>
                  Build a portfolio: Create a portfolio of your work that
                  showcases your design skills and process. This will help you
                  demonstrate your abilities to potential employers and clients.
                </li>
                <br />
                <li>
                  Gain experience: Work on real-world projects, either as a
                  freelancer or as part of a team. Participate in design
                  challenges, hackathons, or online design communities to gain
                  experience and exposure.
                </li>
                <br />
                <li>
                  Network with other designers: Attend design events,
                  conferences, and meetups to meet other designers and learn
                  about the latest industry trends. You can also join online
                  design communities to connect with designers from all over the
                  world.
                </li>
                <br />
                <li>
                  Stay up-to-date: Continuously educate yourself and stay
                  current with the latest design trends and technologies.
                  Participate in ongoing learning opportunities, such as online
                  courses and workshops, to enhance your skills.
                </li>
                <br />
                <li>
                  Specialize in a specific area: Consider specializing in a
                  specific area of UX design, such as interaction design, user
                  research, or accessibility, to become a specialist in your
                  field.
                </li>
                <br />
                <li>
                  Seek feedback: Ask for constructive feedback from your peers,
                  mentors, and clients to improve your skills and grow as a
                  designer.
                </li>
              </ol>
            </p>
            <br />
            <p>
              By following these steps and continuously practicing and refining
              your skills, you can become a professional UX designer.
            </p>
          </div>
          <div className="d-none d-lg-block col-lg-5 second">
            <div className="more-topics">
              <h4 className="fs20 mb-6">More Topics:</h4>
              {location?.pathname.toLowerCase() == "/blogpost_2" || (
                <Link to="/blogpost_2">
                  <div className="topic">
                    <span className="title">
                      The Impact of AI on UX Design.
                    </span>
                    <img
                      src="/assets/img/Blog_1/Small1.png"
                      alt=""
                      className="topic-img"
                    />
                  </div>
                </Link>
              )}
              {location?.pathname.toLowerCase() == "/blogpost_3" || (
                <Link to="/blogpost_3">
                  <div className="topic">
                    <span className="title">
                      Examples of using AI in UX design projects.
                    </span>
                    <img
                      src="/assets/img/Blog_1/Small2.png"
                      alt=""
                      className="topic-img"
                    />
                  </div>
                </Link>
              )}

              {location?.pathname.toLowerCase() == "/blogpost_4" || (
                <Link to="/blogpost_4">
                  <div className="topic">
                    <span className="title">
                      What kind of influence a UX designer can have on a
                      project?
                    </span>
                    <img
                      src="/assets/img/Blog_1/Small3.png"
                      alt=""
                      className="topic-img"
                    />
                  </div>
                </Link>
              )}

              {location?.pathname.toLowerCase() == "/blogpost_5" || (
                <Link to="/blogpost_5">
                  <div className="topic">
                    <span className="title">
                      How to be a professional UX Designer
                    </span>
                    <img
                      src="/assets/img/Blog_1/Small40.png"
                      alt=""
                      className="topic-img"
                    />
                  </div>
                </Link>
              )}

              {location?.pathname.toLowerCase() == "/blogpost_1" || (
                <Link to="/blogpost_1">
                  <div className="topic">
                    <span className="title">
                      How can you use chat GPT for UX research?
                    </span>
                    <img
                      src="/assets/img/Blog_1/Small5.png"
                      alt=""
                      className="topic-img"
                    />
                  </div>
                </Link>
              )}
            </div>
          </div>{" "}
          <div className="py-16"></div>
        </section>
      </div>
    </Main>
  );
}
