import Main from "../layouts/Main";

import "./About.css";
export default function About() {
  return (
    <Main variant="main">
      <div id="about">
        <div className="py-10 d-none d-sm-block"></div>
        <section className="row">
          <div className="order-2 order-sm-1 col-12 col-lg-6 first">
            <h1 className="d-none d-sm-inline text-main fs40">
              Hey there! I am Mahsa.{" "}
            </h1>
            <h1 className="d-block d-sm-none text-center text-main fs32 w-100">
              Hey there! <br /> I am Mahsa.{" "}
            </h1>
            <br />
            <br />
            <div className="content">
              Hello from Toronto, Ontario! I'm a UX/UI designer weaving art and
              technology to craft user-centric digital experiences.
              <br />
              <br />
              With roots dating back to 2018, my journey as a UX/UI designer has
              spanned the globe, leaving a trail of impactful work. Childhood
              doodles evolved into high school newspaper illustrations, and an
              early interest in computer engineering ultimately merged with a
              Master's in Animation. Here, I used art to provoke thought and
              empathy.
              <br />
              <br />
              Driven by empathy and curiosity, I found my calling in UX/UI
              design. Self-study and freelancing sharpened my skills, leading me
              to Canada for advanced studies at Seneca College. My journey
              continued with contributions to a Canadian startup.
              <br />
              <br />
              Today, I am a communicator bridging technology and design,
              crafting visually captivating and meaningful digital experiences.
              Beyond my craft, I explore diverse art forms, embrace outdoor
              adventures, and seek balance.
              <br />
              <br />
              I'm now on the hunt for a full-time UX/UI design role in Toronto,
              eager to contribute my passion and expertise to a team in pursuit
              of excellence.
            </div>
            <div className="py-9 d-block d-sm-none"></div>
          </div>
          <div className="order-1 order-sm-2 col-12 col-lg-6 second">
            <img
              src="/assets/img/About/MyPhoto_About.jpg"
              alt="portfolio face"
              className="avatar-wobble"
            />
          </div>
          <div className="col-12 order-3 mt-15 first d-none d-sm-block">
            <table>
              <tbody className="align-top fs16">
                <tr>
                  <td rowspan="3" className="w-20 fs32 fw-bold">
                    Education:
                  </td>
                  <td className="w-20 pe-8 pb-4">
                    <span className="fs20 fw-bold text-darkblack">
                      Diploma of Game and Interactive Media Design
                    </span>
                    <br />
                    <span className="fs18 text-lightgray fst-italic">
                      Seneca College - Toronto
                    </span>
                    <br />
                    <span className="fs18 text-lightgray">
                      May 2021 - Aug 2022
                    </span>
                  </td>
                  <td className="w-40 text-lightgray bl-black px-8 pb-4">
                    Grade: A With honors
                    <br />
                    Throughout this program, I developed the following skills:
                    Project management, User interface (UI), <br />
                    User experience (UX) Interactive design, <br />
                    Web development, 3D environments, Audio and video editing,
                    Game and motion design
                  </td>
                </tr>
                <tr>
                  <td className="pe-8 py-4">
                    <span className="fs20 fw-bold text-darkblack">
                      Master of Art - Animation
                    </span>
                    <br />
                    <span className="fs18 text-lightgray fst-italic">
                      Soore University - Iran
                    </span>
                    <br />
                    <span className="fs18 text-lightgray">
                      Sep 2017 - Aug 2019
                    </span>
                  </td>
                  <td className="text-lightgray bl-black px-8 py-4">
                    Through my Master's in Animation, I've honed skills in
                    character design, storyboarding, and advanced software.
                    Collaborating with peers and faculty, I've merged
                    storytelling with technology, equipping me to convey
                    compelling narratives through captivating visuals.
                  </td>
                </tr>
                <tr>
                  <td className="pe-8 py-4">
                    <span className="fs20 fw-bold text-darkblack">
                      Bachelor of Computer Engineering
                    </span>
                    <br />
                    <span className="fs18 text-lightgray fst-italic">
                      Azad University - Iran
                    </span>
                    <br />
                    <span className="fs18 text-lightgray">
                      Sep 2011 - Aug 2016
                    </span>
                  </td>
                  <td className="text-lightgray bl-black px-8 py-4">
                    Obtaining my Bachelor's in Computer Engineering, I've
                    mastered coding, system design, and problem-solving. This
                    program has honed my skills, equipping me to create
                    innovative solutions and adapt in a fast-changing tech
                    landscape
                  </td>
                </tr>
                <tr className="py-4 " style={{ height: "20px" }}>
                  <td></td>
                </tr>
                <tr className="py-4 " style={{ height: "20px" }}>
                  <td></td>
                  <td className="bt-black"></td>
                  <td className="bt-black"></td>
                </tr>
                <tr>
                  <td rowspan="5" className="fs32 fw-bold">
                    Experience:
                  </td>
                  <td className=" pe-8 py-4">
                    <span className="fs20 fw-bold text-darkblack">
                      UX/UI Designer
                    </span>
                    <br />
                    <span className="fs18 text-lightgray fst-italic">
                      Appsport Inc - Toronto
                    </span>
                    <br />
                    <span className="fs18 text-lightgray">
                      Apr 2023 - Present
                    </span>
                  </td>
                  <td className="text-lightgray bl-black px-8 py-4">
                    In a startup, I drove user-centric designs, boosting mobile
                    app adoption by 20%, and enhancing engagement through
                    gamification. I established design systems, ensured
                    accessibility compliance, and secured a 90% concept approval
                    rate.
                  </td>
                </tr>
                <tr>
                  <td className="pe-8 py-4">
                    <span className="fs20 fw-bold text-darkblack">
                      UX/UI Designer
                    </span>
                    <br />
                    <span className="fs18 text-lightgray fst-italic">
                      Freelance
                    </span>
                    <br />
                    <span className="fs18 text-lightgray">
                      May 2021 - Apr 2023
                    </span>
                  </td>
                  <td className="text-lightgray bl-black px-8 py-4">
                    Designed for varied industries (healthcare, sports,
                    education, retail, e-commerce) with user-centric interfaces,
                    achieving 20% higher satisfaction and 25% fewer support
                    requests. Maintained brand consistency through typography,
                    colors, and icons, and managed concurrent projects, staying
                    updated on design trends and tech advancements.
                  </td>
                </tr>
                <tr>
                  <td className="pe-8 py-4">
                    <span className="fs20 fw-bold text-darkblack">
                      UX/UI Designer
                    </span>
                    <br />
                    <span className="fs18 text-lightgray fst-italic">
                      Techmad Co
                    </span>
                    <br />
                    <span className="fs18 text-lightgray">
                      Oct 2018 - Apr 2021
                    </span>
                  </td>
                  <td className="text-lightgray bl-black px-8 py-4">
                    Crafted user interfaces for web and mobile apps, optimizing
                    user experience. Collaborated with cross-functional teams to
                    define user needs, iterate designs, and meet tight deadlines
                    without compromising quality. Led user-centered design
                    adoption, resulting in a 25% user satisfaction boost.
                  </td>
                </tr>
                <tr>
                  <td className="pe-8 py-4">
                    <span className="fs20 fw-bold text-darkblack">
                      Graphic Designer
                    </span>
                    <br />
                    <span className="fs18 text-lightgray fst-italic">
                      Techmad Co
                    </span>
                    <br />
                    <span className="fs18 text-lightgray">
                      Oct 2018 - Apr 2021
                    </span>
                  </td>
                  <td className="text-lightgray bl-black px-8 py-4">
                    Led comprehensive brand design, crafting logos, business
                    cards, and brand identity guidelines. Drove impactful email
                    campaigns and designed engaging social media content.
                    Established motion graphic standards, enhancing brand
                    consistency.
                  </td>
                </tr>
                <tr>
                  <td className="pe-8 py-4">
                    <span className="fs20 fw-bold text-darkblack">
                      3D Modeler and Character Designer
                    </span>
                    <br />
                    <span className="fs18 text-lightgray fst-italic">
                      Axon Co
                    </span>
                    <br />
                    <span className="fs18 text-lightgray">
                      May 2018 - Sep 2018
                    </span>
                  </td>
                  <td className="text-lightgray bl-black px-8 py-4">
                    Designed character model sheets for 3D reference, created
                    high-quality 3D models using Maya, collaborated across
                    teams, and provided constructive feedback in asset reviews.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <div className=" d-none d-sm-block"></div>
      </div>
    </Main>
  );
}
