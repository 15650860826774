import Main from "../layouts/Main";
import "./Onra.css";
export default function Onra() {
  return (
    <Main headerSecondary>
      <div id="onra">
        <section className="hero">
          <div className="d-none d-sm-block">
            <img
              src="/assets/img/Onra/Hero.png"
              alt=""
              className="img1 position-absolute"
            />
          </div>

          <h1 className="onra-title">Onra.ca</h1>
          <h2 className="onra-sub-title">Real State Platform</h2>
          <h2 className="onra-sub-link">https://www.onra.ca</h2>
        </section>
        <section className="prototype">
          <div className="py-6 py-sm-9"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            Why Onra.ca?
          </h3>
          <p>
            Onra.ca was conceived to address the evolving needs of home buyers
            in Ontario and Alberta. The essence of Onra.ca lies in bridging the
            gap between home buyers and real estate projects, both in pre-sale
            and resale phases. In an increasingly digital age, where convenience
            and accessibility are paramount, Onra.ca serves as a comprehensive
            platform for potential home buyers to explore, evaluate, and engage
            with various property offerings.
          </p>
          <a href="https://www.onra.ca/" target="_blank" rel="noreferrer">
            <button className="inter">Visit Onra.ca</button>
          </a>
          <div className="py-6 py-sm-9"></div>
          <div className="video-container">
            <img
              src="/assets/img/Onra/why.png"
              alt=""
              className="img-fluid d-block d-sm-none mx-auto"
            />
            <img
              src="/assets/img/Onra/why.png"
              alt=""
              className="img-fluid d-none d-sm-block mx-auto"
            />
          </div>
          <div className="py-6 py-sm-9"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            Who’s it for?
          </h3>
          <p>
            Onra.ca caters to prospective home buyers and investors in Ontario
            and Alberta, providing comprehensive information on pre-sale and
            resale properties. It serves individuals, families, and investors
            interested in exploring the real estate market in these regions.
            Onra.ca offers easy accessibility and valuable resources for anyone
            seeking properties or investment opportunities in Ontario and
            Alberta.
          </p>
          <div className="py-6 py-sm-9"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">My role</h3>
          <p>
            In my role as a UI/UX designer for Onra.ca, I spearheaded the entire
            design process from conceptualization to delivery. This involved
            ideating user interface designs, conducting user experience
            research, developing wireframes and prototypes, and delivering
            polished visual designs. I prioritized creating a seamless and
            intuitive user experience to meet the needs of both home buyers and
            the website owner, Hamed.
          </p>
          <div className="py-6 py-sm-9"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">Goals</h3>
          <p>
            <ul>
              <li>
                Onra.ca aims to offer a seamless user experience, enabling
                visitors to access vital information about new real estate
                projects effortlessly. It provides detailed images, floor plans,
                payment options, and facility details in a user-friendly manner,
                empowering home buyers to make informed decisions.
              </li>
              <br />
              <li>
                Onra.ca facilitates direct communication between Hamed and
                prospective buyers through integrated contact forms, fostering
                trust and facilitating meaningful connections that lead to
                successful home purchases.
              </li>
            </ul>
          </p>
          <div className="py-6 py-sm-5"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">Road Map</h3>
          <p>
            Spanning a total of two weeks, the project followed a structured
            timeline:
          </p>
          <div className="py-6 py-sm-3"></div>
          <div className="overview-image mx-auto">
            <img
              src="/assets/img/Onra/road-map.png"
              alt=""
              className="img-fluid "
            />
          </div>
          <div className="py-6 py-sm-9"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            The biggest challenge
          </h3>
          <p>
            As I embarked on the design journey for Onra.ca, the biggest
            challenge I encountered was maintaining simplicity amidst the
            complexity of real estate information. At its core, Onra.ca aimed to
            be a user-friendly platform, but with the vast array of property
            details and features, achieving simplicity while providing
            comprehensive information posed a significant hurdle.
          </p>
          <div className="py-6 py-sm-6"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            We had to take a step back
          </h3>
          <p>
            We had to take a step back because our initial designs risked
            overwhelming users with too much information. Recognizing this, we
            reevaluated our approach and focused on prioritizing essential
            elements while streamlining the user interface. By distilling the
            most critical information and presenting it in a clear and concise
            manner, we ensured that users could navigate the platform
            effortlessly, without feeling overwhelmed.
          </p>
          <div className="py-6 py-sm-6"></div>
          <div className="overview-image mx-auto">
            <img
              src="/assets/img/Onra/step-back.png"
              alt=""
              className="img-fluid "
            />
          </div>
          <div className="py-6 py-sm-3"></div>
          <p>
            We provided additional project information in PDF and Excel file
            formats so that users can access more detailed information if
            needed. This format is also advantageous for the administrator, as
            any changes can be easily made to the Excel and PDF files and
            updated through the dashboard.{" "}
          </p>
          <div className="py-6 py-sm-9"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            One pivotal moment
          </h3>
          <p>
            One pivotal moment in the design process occurred when Hamed
            expressed his admiration for the simplicity and ease of use found on
            the Tesla website. Having recently purchased a Tesla and spent
            considerable time exploring its website, Hamed was drawn to its
            minimalist aesthetic and intuitive interface.
          </p>
          <div className="py-6 py-sm-9"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            Crafting Onra.ca's Design Vision
          </h3>
          <p>
            This revelation served as a guiding light for the design direction
            of Onra.ca, particularly the homepage. Hamed's request was clear: he
            desired a website interface that mirrored the simplicity and
            elegance of Tesla's design, providing users with a seamless browsing
            experience akin to navigating the Tesla website.
          </p>
          <br />
          <p>
            This revelation served as a guiding light for the design direction
            of Onra.ca, particularly the homepage. Hamed's request was clear: he
            desired a website interface that mirrored the simplicity and
            elegance of Tesla's design, providing users with a seamless browsing
            experience akin to navigating the Tesla website.
          </p>
          <div className="py-6 py-sm-4"></div>
          <div className="overview-image mx-auto">
            <img
              src="/assets/img/Onra/vision.png"
              alt=""
              className="img-fluid "
            />
          </div>
          <div className="py-6 py-sm-11"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            One of the key adaptations
          </h3>
          <p>
            One of the key adaptations we made was integrating with MLS
            (Multiple Listing Service) search functionality. This integration
            was crucial for providing up-to-date and accurate property listings
            to users. However, incorporating MLS data seamlessly into the
            platform presented technical and design challenges.{" "}
          </p>
          <br />
          <p>
            We had to adapt because the MLS data structure differed from our
            initial design assumptions. This required us to revisit our
            interface layouts and refine our search functionalities to
            accommodate the diverse range of property listings. Through
            collaboration with developers and MLS providers, we successfully
            integrated the MLS search feature while maintaining the overall
            simplicity and usability of the platform.{" "}
          </p>
          <div className="py-6 py-sm-10"></div>
          <div className="overview-image mx-auto">
            <img
              src="/assets/img/Onra/adoptions.png"
              alt=""
              className="img-fluid "
            />
          </div>
          <div className="py-6 py-sm-10"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            Consistency in design was another challenge
          </h3>
          <p>
            Consistency in design was another challenge we encountered during
            the development process. With multiple features and sections within
            the platform, ensuring visual coherence across all elements was
            essential for a cohesive user experience.{" "}
          </p>
          <br />
          <p>
            To address this challenge, we established clear design guidelines
            and standards early in the process. We defined consistent
            typography, color schemes, and layout principles to maintain visual
            harmony throughout the platform. Regular design reviews and
            iterations helped us refine and enforce these standards, ensuring a
            polished and unified look and feel across Onra.ca.
          </p>
          <div className="py-6 py-sm-10"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            Designing the administration dashboard <br /> presented its own set
            of challenges{" "}
          </h3>
          <p>
            Lastly, designing the administration dashboard presented its own set
            of challenges. While the primary focus was on creating a seamless
            experience for users, it was equally important to provide an
            intuitive and efficient interface for Hamed, the website owner, to
            manage property listings and communicate with potential buyers.{" "}
          </p>
          <br />
          <p>
            We had to adapt because the administration dashboard required a
            delicate balance between functionality and simplicity. Through
            iterative design and feedback sessions with Hamed, we refined the
            dashboard interface to prioritize essential tasks and streamline
            administrative workflows. The result was an intuitive and
            user-friendly dashboard that empowered Hamed to efficiently manage
            Onra.ca's content and interactions with users.
          </p>
          <div className="py-6 py-sm-10"></div>
          <div className="overview-image mx-auto">
            <img
              src="/assets/img/Onra/dashboard.png"
              alt=""
              className="img-fluid "
            />
          </div>
          <div className="py-6 py-sm-8"></div>
          <p>
            Despite these challenges, the collaborative effort between design,
            development, and stakeholder teams enabled us to overcome obstacles
            and deliver Onra.ca on schedule. Our journey from conceptualization
            to launch was not without its twists and turns, but ultimately, it
            was a testament to our collective dedication to creating a valuable
            and user-centric platform for home buyers and investors in Ontario
            and Alberta.
          </p>
          <br />
          <p>
            This project was recently launched and requires some minor changes
            in the frontend code.
          </p>
          <div className="py-6 py-sm-10"></div>
          <div className="overview-image mx-auto">
            <img
              src="/assets/img/Onra/review.png"
              alt=""
              className="img-fluid "
            />
          </div>
          <div className="py-6 py-sm-8"></div>
        </section>
      </div>
    </Main>
  );
}
