import Main from "../layouts/Main";
import "./AppleDry.css";
export default function AppleDry() {
  return (
    <Main headerSecondary>
      <div id="apple-dry">
        <section className="hero">
          <div className="">
            <img
              src="/assets/img/Apple_Dry/imac-mochup.png"
              alt=""
              className="img2"
            />
          </div>
          <h1 className="plando-title">Apple Dry</h1>
          <h2 className="plando-sub-title">
            Planner and Goal-tracking Web Application
          </h2>
        </section>
        <section className="prototype">
          <p>
            Dried fruits have been always considered a healthy snack. Dried
            fruits are a very rich source of vitamins, minerals, fibre as well
            as antioxidants. So, the idea of an online shopping platform for
            selling dried fruits products was initiated and developed to pitch
            to potential business partners in order to find a way to realize it.
            To address this platform, I and another UX designer collaborated
            together to design a desktop and mobile device website Called "Apple
            Dry ".
          </p>
          <a
            href="https://www.figma.com/file/i1f0rZjBgqoesF5D2Snow3/High_Fid_Planner?type=design&node-id=0%3A1&mode=design&t=PKnLtAlgYWHsw07u-1"
            target="_blank"
            rel="noreferrer"
          >
            <button className="inter">View Figma Prototype</button>
          </a>
          <div className="py-6 py-sm-9"></div>
          <div className="video-container">
            <img
              src="/assets/img/Apple_Dry/Desktop_Demo.png"
              alt=""
              className="img-fluid d-none d-sm-block"
            />
          </div>
          <div className="py-6 py-sm-9"></div>
        </section>
        <section className="overview">
          <div className="header">
            <span className="inter">Overview</span>
          </div>
          <div className="overview-image mx-auto">
            <img
              src="/assets/img/Apple_Dry/Overview_AppleFry.png"
              alt=""
              className="img-fluid d-none d-sm-block"
            />
            <img
              src="/assets/img/Apple_Dry/duration_mobile.png"
              alt=""
              className="img-fluid d-block d-sm-none mx-auto"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            What is Apple Dry
          </h3>
          <p className="mx-auto">
            Apple dry is a website for an online shop that offers organic dried
            fruits with good quality and a wide range of varieties. Our target
            users a wide range of ages who are interested in consuming dried
            fruit products as healthy snacks. Especially, Kids' health is
            considered in priority by replacing these products rather than junk
            snacks like potato chips, chocolate bars, and candy. The business
            also focuses on sustainable production and packaging.
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center pt-1">
            Problem
          </h3>
          <p className="mx-auto">
            <ul>
              <li>
                Studies show that people especially children are accustomed to
                eating unhealthy snacks during the day.
              </li>
              <li>
                Therefore, there is a need for an online brand new e-commerce
                website that is specifically designed for Healthy and organic
                kids-friendly products.
              </li>
            </ul>
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-11">Goals</h3>
          <p className="mx-auto">
            <ul>
              <li>
                <li>
                  Our project goal is to make an intuitive, user-friendly, and
                  child health priorities website for the business, as well as
                  provide a convenient customer experience.
                </li>
                <li>
                  The website also lets customers know that organic products are
                  produced and distributed in a sustainable manner and
                  packaging.{" "}
                </li>
              </li>
            </ul>
          </p>
          <div className="py-8"></div>
          <div className="header ">
            <span className="inter">The Process</span>
          </div>
          <p className="mx-auto">
            Our seven weeks design sprint was divided into Six stages: with the
            goals in mind, our team utilized the double diamond design based on
            the Design Thinking methodology. It was not a linear path, we
            bounced between stages as the project progressed.
          </p>
          <div className="overview-image mx-auto">
            <img
              src="/assets/img/Apple_Dry/process_appledry.png"
              alt=""
              className="img-fluid d-none d-sm-block"
            />
            <img
              src="/assets/img/Apple_Dry/process_appledry_mobile.png"
              alt=""
              className="img-fluid d-block d-sm-none mx-auto"
            />
          </div>
          <div className="py-5 py-sm-15"></div>
          <div className="header">
            <span className="inter">Discover</span>
          </div>
          <p className="mx-auto">
            The first step was to learn about defining our target users and
            comprehend their needs and values. For this purpose, we chose and
            followed the 4 research methods as follows:
          </p>
          <div className="interview-image mx-auto">
            <img
              src="/assets/img/Apple_Dry/discover.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            Secondary Research
          </h3>
          <p className="mx-auto mt-1">
            Firstly, I did research on Healthy snakes and organic products
            before diving into the design process by reading numerous articles
            and blogs. Secondary studies show that people are seeking healthy
            and nutritious snacks, especially for their children to educate them
            to replace them with regular Junke snacks. To further understand
            their concerns and needs and gather feedback from the existing user,
            we prepared an online survey and conducted interviews with some of
            them.{" "}
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center">Survey</h3>
          <p className="mx-auto mt-1">
            We set up an online survey and asked them to fill out 15 key
            questions. We received 60 responses in total. the result of this
            survey help us to investigate what the user needs were as:
            <ul>
              <li>
                <li>
                  we understand that most of the participants are willing to buy
                  and use Dried fruits.
                </li>
                <li>
                  now, we know that they prefer to use them as healthy snacks.
                </li>
                <li>
                  Also, it helps us to consider the priority of quality, price,
                  and sustainable packaging in our design way.
                </li>
                <li>
                  about 33% of children are persuaded to use Dried fruits.{" "}
                </li>
              </li>
            </ul>
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center">Interview</h3>
          <p className="mx-auto mt-1">
            Based on our survey results, we decided to interview our potential
            users. So, 7 people who declaration of interest in this criterion
            were interviewed. the result gave us valuable information.{" "}
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            Main Idenrified Challenges
          </h3>
          <p className="mx-auto mt-1">
            <ul>
              <li>
                <li>Simple design</li>
                <li>Easily find information and products</li>
                <li>Kids user friendly</li>
                <li>Without preservative products</li>
                <li>Environmental friendly products</li>
              </li>
            </ul>
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            Competitive Analysis
          </h3>
          <p className="mx-auto mt-1">
            After gaining a better understanding of users' thoughts and
            feelings, we conducted thorough research on 7 retailers' websites.{" "}
          </p>
          <div className="competitive-image mx-auto">
            <img
              src="/assets/img/Apple_Dry/Competitive_Analysis.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            Major Takeaways
          </h3>
          <p className="mx-auto mt-1">
            Here are the top aspects of the competitors' websites that later
            inspired our design:
            <ul>
              <li>Highlighting the business's stories and values</li>
              <li>Easy access to the user account</li>
              <li>Showing product features and nutrition</li>
              <li>Free shipping options</li>
              <li>easy access to customer reviews for products</li>
            </ul>
          </p>
          <div className="py-7 py-sm-10"></div>
          <div className="header ">
            <span className="inter">Define</span>
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            Affinity Diagram
          </h3>
          <p className="mx-auto mt-1">
            Before creating the personas, we conducted an affinity diagram to
            analyze the interviews and learn about users' pain points and main
            challenges. The results from this activity guided our design.
          </p>
          <div className="competitive-image mx-auto">
            <img
              src="/assets/img/Apple_Dry/Affinity_Diagram.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">Persona</h3>
          <p className="mx-auto mt-1">
            Based on the user research, and interviews we set up our primary
            persona. We referred to it throughout the entire product development
            process.{" "}
          </p>
          <div className="userflow-image mx-auto">
            <img
              src="/assets/img/Apple_Dry/Persona.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">Scenario</h3>
          <p className="mx-auto mt-1">
            Cindy needs to buy dried bananas as an afternoon snack for her kids.
            Because of the pandemic, decides to shop online. One day she
            discussed this matter with her daughter's teacher (Sarah), and Sarah
            introduced her to an online shop website called Apple Dry website.
            She selects Dried bananas from the product categories. She filters
            based on her needs. She then selects two different products and goes
            to the checkout.{" "}
          </p>
          <div className="py-7 py-sm-10"></div>
          <div className="header ">
            <span className="inter">Develop</span>
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-8 mt-sm-16">
            User Flow
          </h3>
          <p className="mx-auto mt-5 px-5">
            Considering user needs and the project goals, we constructed a user
            flow that describes the pathways user might take while purchasing an
            item. This process helped me in
            <ul>
              <li>
                Identifying the key screens and actions users might require.
              </li>
              <li>Understanding ways users can interact with the product</li>
              <li>Allowing me to see navigation through user goals.</li>
            </ul>
          </p>
          <div className="uikit-image mx-auto">
            <img
              src="/assets/img/Apple_Dry/User_Flow.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-8 mt-sm-16">
            Site Map
          </h3>
          <p className="mx-auto mt-5 px-5">
            Based on our findings from the research and interviews, which
            revealed the user goals and needs, we created the sitemap for Apple
            Dry website. We designed key screens and decided where the main
            features would be located on the website and how they would relate
            to each other.
          </p>
          <div className="uikit-image mx-auto">
            <img
              src="/assets/img/Apple_Dry/sitemap.png"
              alt=""
              className="img-fluid"
            />
          </div>{" "}
          <div className="py-9"></div>
          <div className="header ">
            <span className="inter">Design</span>
          </div>
          <div className="py-2"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-13">
            Sketches and low Fi design{" "}
          </h3>
          <p className="mx-auto mt-sm-6 px-sm-5">
            Diving into sketching, I started the design stage by sketching out
            ideas for layouts and referencing the task flows, user flow, and
            sitemap. These low-fidelity wireframes were a quick and efficient
            method for developing visual hierarchy and planning out key screens
            required for a user to their favorite Dried fruits. <br />
            Taking the most effective concepts and creating low fidelity
            wireframes also ensured that chosen solutions worked well on screen
            and integrated seamlessly with one another.{" "}
          </p>
          <div className="test-image mx-auto">
            <img
              src="/assets/img/Apple_Dry/Low_Fi.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-13">
            Visual and UI Design
          </h3>
          <div className="py-2"></div>
          <p className="mx-auto mt-0 px-sm-3">
            While designing the user interface, our main focus was on creating a
            natural and attractive design and outstanding visual details.
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-13">
            Mood-board
          </h3>
          <div className="py-2"></div>
          <p className="mx-auto mt-0 px-sm-3">
            We created this mood board with the goal of reflecting Apple Dry's
            approach to organic products and environmental sustainability.{" "}
          </p>
          <div className="usertest-image mx-auto">
            <img
              src="/assets/img/Apple_Dry/Mood_Board.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">Colors</h3>
          <p className="mx-auto px-sm-3">
            After thorough research on the psychology of colors, analyzing
            competitors and getting inspiration from our mood board, we choose 3
            colors suitable for branding the business and website design. We
            also made sure that our color palette selection met the
            accessibility criteria for color blindness.
          </p>
          <div className="testing-image mx-auto">
            <img
              src="/assets/img/Apple_Dry/Colors.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">UI Kit</h3>
          <p className="mx-auto px-sm-3">
            Finally, My UI kit for high-fi interfaces consists of colors,
            typography, Icons, and dimensions for elements that must remain
            consistent across all screens.{" "}
          </p>
          <div className="uikit mx-auto">
            <img
              src="/assets/img/Apple_Dry/UI_Kit.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-5 py-sm-10"></div>
          <p className="mx-auto mt-5 px-5">
            In designing the following aspects are considered:
            <ul>
              <li>
                Applying the Graphik typograph to follow the minimal design and
                illustrating the
              </li>
              <li>
                Applying an adequate amount of white space to bring more clarity
                and follow the minimal design
              </li>
            </ul>
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-8 mt-sm-16">
            Usability test and Iteration
          </h3>
          <p className="mx-auto mt-5 px-5">
            Phase One/ Usability Testing
            <br />
            <br />
            The hi-fi prototype was tested on a total of 9 people. Users were
            asked to place an order as a hypothetical task on the desktop
            version. We also asked probing questions to gauge the user's
            attitudes during the usability testing session.
            <br />
            <br />
            All participants expressed that the flow is easy to follow. However,
            some pain points were identified in our user interviews. The image
            below demonstrates the pain points, along with the iteration we made
            to address them.
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-8 mt-sm-16">
            Home page iterations
          </h3>
          <p className="mx-auto mt-5 px-5">
            the first and final wireframe of the home page is shown. The final
            wireframe comes from many usability tests and some iterations. the
            changes to the final high-fidelity prototype are as below.
            <ol>
              <li>
                In the navbar, the user wants to change the size and typography.
                also, they comment to change the LOGO in the small size without
                the frame.
              </li>
              <li>
                In the second part, the user gives us some comments to have a
                background for the hero image and change the color of the text.
              </li>
              <li>
                In the third part, a photo of each group is changed and resized.
              </li>
              <li>For part four, we used the frame for each category.</li>
              <li>
                users comment to remove this section and just have it in the
                navbar.
              </li>
              <li>
                the presentation of this section was edited and its UI was
                corrected.
              </li>
            </ol>
          </p>
          <div className="uikit mx-auto">
            <img
              src="/assets/img/Apple_Dry/iteration.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-8 mt-sm-16">
            Product category page iterations
          </h3>
          <p className="mx-auto mt-5 px-5">
            Firstly, the usability test was shown that simply the address bar
            was more user-friendly and minimal. <br />
            Secondly, The left sidebar was designed to filter products, allowing
            users to navigate via drop-down menus and have easy access to all
            categories. The sort bar has been placed on the right side of the
            product page. <br />
            Thirdly, product information is rearranged and redesigned. <br />
            finally, to access the user to the number of products on each page,
            the Product number has been designed.
          </p>
          <div className="uikit mx-auto">
            <img
              src="/assets/img/Apple_Dry/Iteration_2.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-8 mt-sm-16">
            Product page
          </h3>
          <p className="mx-auto mt-5 px-5">
            The Product Details section was redesigned based on feedback I
            received from usability testing.
            <ol>
              <li>
                the review rate was very important for users so I have designed
                it upside the price rate.
              </li>
              <li>
                the dropdown unite number has been designed to easy access.
              </li>
              <li>
                tip. nutrition information and reviews comments have been
                designed based on a dropdown menu in a simple and minimal way
                for the user.
              </li>
              <li>
                recently view sections were added to address users about
                interesting items.
              </li>
            </ol>
          </p>
          <div className="uikit mx-auto">
            <img
              src="/assets/img/Apple_Dry/Iteration_3.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-5 py-sm-10"></div>
          <div className="header">
            <span className="inter">DELIVER</span>
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            High Fidelity Design
          </h3>
          <p className="mx-auto mt-5 px-5">
            <ul>
              <li>
                After wireframes and visual elements provision, a high-fidelity
                design for desktop and phone was implemented in Figma for all
                pages in our user flow.{" "}
              </li>
            </ul>
          </p>
          <div className="high-fidelity-image mx-auto">
            <img
              src="/assets/img/Apple_Dry/High_Fi.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <p className="mx-auto px-5 text-center">
            High-fidelity design for desktop
          </p>
          <div className="high-fidelity-image mx-auto">
            <img
              src="/assets/img/Apple_Dry/High_Fi_Mobile.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <p className="mx-auto px-5 text-center">
            High-fidelity design for mobile
          </p>
          <div className="py-4 py-sm-9"></div>
          <div className="header">
            <span className="inter">REFLECTIONS</span>
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            Personal Learning
          </h3>
          <p className="mx-auto">
            <ul className="ms-sm-5">
              <li>
                Designing a product from end to end was a challenge. During this
                project, we encountered so many levels of decision-making and
                prioritization. I learned how important it is to always turn to
                the user's needs whenever we hit a wall. Moreover, I learned how
                to evaluate the findings more effectively and make changes that
                support business goals.
              </li>
              <li>
                Simplicity is a strength. As a designer, we are often lured by
                trendy designs. But, we must always remember the ‘why’. The
                primary goal is to understand users, and their problems and then
                come up with a design that solves them.
              </li>
            </ul>
          </p>
          <h3 className="fs18 fssm32 fw-bold inter text-center text-capitalize">
            Next Step
          </h3>
          <p className="mx-auto">
            <ul className="ms-sm-5">
              <li>
                The time constraints prevented a number of tasks from being
                included that should be addressed next, such as:
              </li>
              <li>
                Evaluating the current website design with multiple methods of
                testing including Maze software.
              </li>
              <li>Design a mobile-friendly version.</li>
            </ul>
          </p>
          <div className="py-10"></div>
        </section>
      </div>
    </Main>
  );
}
