import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Header2 from "../../components/Header2";
import Footer2 from "../../components/Footer2";

export default function Main(props) {
  // console.log(props?.variant);
  return (
    <>
      {props.hideHeader || props.headerSecondary ? (
        <Header2 variant={props?.variant} />
      ) : (
        <Header variant={props?.variant} />
      )}
      {props.children}
      {props.hideFooter || props.footerSecondary ? <Footer2 /> : <Footer />}
    </>
  );
}
