import Main from "../layouts/Main";
import "./SibBakery.css";
export default function SibBakery() {
  return (
    <Main headerSecondary>
      <div id="sib_bakery">
        <section
          className="hero"
          style={{
            backgroundImage: "url(/assets/img/Sib/hero-back.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <h1 className="sib-title">Sib Bakery</h1>
          <h2 className="sib-sub-title">Local Bakery Based in Seattle</h2>
          <img src="/assets/img/Sib/SIB_Banner.png" alt="" className="" />
        </section>
        <section className="description">
          <div className="py-3"></div>
          <div className="py-6 fs18 fssm32 fw-bold inter text-center mt-5">
            About Sib Bakery
          </div>
          <p className="px-3 px-0">
            Sib Bakery is a local homemade bakery based in Seattle, Washington,
            offering traditional Persian cookies and cakes made with fresh,
            high-quality ingredients, free from preservatives and dairy
            products. <br />
          </p>
          <div className="py-3"></div>
          <div className="row w-88">
            <div className="col-8 d-flex flex-column align-items-center justify-content-center">
              <div style={{ width: "fit-content0" }}>
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  preload="auto"
                  controls={false}
                  style={{}}
                  className="w-100"
                  alt=""
                  src={"/assets/img/Sib/IMG_141.mp4"}
                >
                  <source type="video/mp4" />
                </video>
              </div>
              <a
                href="https://www.figma.com/proto/oFpsaZGeE9CgBcFviXHzbT/Sib-Bakery-(Portfolio)?page-id=0%3A1&type=design&node-id=1-170&viewport=1597%2C1598%2C0.11&t=hLmTYCcMyF8Ksl8n-1&scaling=min-zoom&starting-point-node-id=1%3A170&mode=design"
                target="_blank"
                rel="noreferrer"
              >
                <button className="inter">Web Prototype</button>
              </a>
            </div>
            <div className="col-4 d-flex flex-column align-items-center justify-content-center">
              <div style={{ width: "fit-content" }}>
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  preload="auto"
                  controls={false}
                  style={{}}
                  className="w-100"
                  alt=""
                  src={"/assets/img/Sib/IMG_142.mp4"}
                >
                  <source type="video/mp4" />
                </video>{" "}
              </div>
              <a
                href="https://www.figma.com/proto/oFpsaZGeE9CgBcFviXHzbT/Sib-Bakery-(Portfolio)?page-id=1%3A19679&type=design&node-id=1-21419&viewport=945%2C3088%2C0.15&t=MjktN2Q56eY5JPld-1&scaling=scale-down&mode=design"
                target="_blank"
                rel="noreferrer"
              >
                <button className="inter">Mobile Prototype</button>
              </a>
            </div>
          </div>
        </section>
        <div className="py-6 py-sm-7"></div>
        <section className="main">
          <div className="header">
            <span className="inter">Overview</span>
          </div>
          <div className="overview-image mx-auto">
            <img
              src="/assets/img/Sib/Overview.png"
              alt=""
              className="img-fluid d-none d-sm-block"
            />
            <img
              src="/assets/img/Sib/Overview.png"
              alt=""
              className="img-fluid d-block d-sm-none mx-auto"
            />
          </div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            Business Needs
          </h3>
          <p className="mx-auto mt-6 mt-sm-0">
            <ul className="mx-0">
              <li>
                Create a website with a Minimal design to Attract customers.
              </li>
              <li>
                Establishing strong relationships with Iranian and non-Iranian
                customers.
              </li>
              <li>
                Promoting the values of homemade and health-conscious treats.
              </li>
            </ul>
          </p>
          <div className="py-5"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center mt-8">
            Main Challenges
          </h3>
          <p className="mx-auto mt-6 mt-sm-0">
            <ul className="mx-0">
              <li>Gaining customer trust with a trustworthy website design.</li>
              <li>
                Clearly showing the bakery's key values: no preservatives, no
                dairy, and fresh ingredients.
              </li>
              <li>
                Making it easy for customers to find and select their preferred
                cakes and cookies.
              </li>
              <li>
                Designing an attractive website that highlights the bakery's
                delicious treats.
              </li>
            </ul>
          </p>
          <div className="py-4 py-sm-15"></div>
          <div className="header">
            <span className="inter">The Process</span>
          </div>
          <div className="py-4 py-sm-6"></div>
          <p className="mx-auto text-center">
            Our fourth weeks design sprint was divided into five stages: <br />{" "}
            It should be noted that iteration is essential at every stage.
          </p>
          <div className="process-image mx-auto">
            <img
              src="/assets/img/Sib/TheProcess.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <p className="mx-auto d-none">
            <table className="fs14 process-table">
              <tbody>
                <tr>
                  <td className="fw-bold">1- Discovery</td>
                  <td className="fw-bold">2- Define</td>
                  <td className="fw-bold">3- Develop</td>
                  <td className="fw-bold">4- Deliver</td>
                </tr>
                <tr>
                  <td>Interview</td>
                  <td>Persona</td>
                  <td>Mid Fidelity Design</td>
                  <td>High Fidelity Design</td>
                </tr>
                <tr>
                  <td>Affinity diagram</td>
                  <td>User Flow</td>
                  <td>Visual Design</td>
                  <td>Usability & Iteration</td>
                </tr>
                <tr>
                  <td>Empathy map</td>
                  <td>Task Flow</td>
                  <td>UI Kits</td>
                  <td>Clickable Prototype</td>
                </tr>
                <tr>
                  <td>Competitive analysis</td>
                  <td>Site Map</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </p>
          <div className="py-4 py-sm-6"></div>
          <div className="header">
            <span className="inter">Discovery</span>
          </div>
          <div className="py-6"></div>
          <p className="px-4 px-sm-6">
            Our first step was researching local bakeries' websites and
            understanding our target users’ needs. thus, the study started with{" "}
            <br />
            <br />
            four phases:
            <br />
            <ol>
              <li>Interview with different types of users</li>
              <li>Affinity diagram</li>
              <li>Empathy map </li>
              <li>Competitive analysis </li>
            </ol>
            <div className="py-1"></div>
            <h3 className="fs18 fssm32 fw-bold inter text-center mt-8">
              1. Interview
            </h3>
            We wanted to figure out:
            <br />
            <ol>
              <li>
                What are people's main needs when they use websites to search
                for homemade bakeries?
              </li>
              <li>How can we encourage them to use our websites?</li>
              <li>What are users' frustrations with local Bakery websites?</li>
            </ol>
            So, we interviewed 7 people. We asked more open-ended questions,
            allowing for a discussion with the interviewees rather than a
            straightforward question-and-answer format. 
            <div className="py-13"></div>
            <h3 className="fs18 fssm32 fw-bold inter text-center mt-8">
              2. Affinity Diagram
            </h3>
            Following the interviews, we successfully utilized the affinity
            diagram to pinpoint and narrow down our customers' specific
            preferences. This valuable tool allowed us to synthesize the
            gathered information, identify common themes, and gain valuable
            insights into customer preferences, ultimately guiding us toward
            more informed decision-making.
          </p>
          <div className="py-7"></div>
          <div className="affinity-image mx-auto">
            <img
              src="/assets/img/Sib/Affinity_Diagaram.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-7"></div>
          <p className="px-4 px-sm-6">
            <h3 className="fs18 fssm32 fw-bold inter text-center mt-8">
              2. Empathy Map
            </h3>
            This empathy map can guide us in tailoring our online presence and
            marketing strategies to meet the customer's preferences and
            expectations better.
          </p>
          <div className="py-5"></div>
          <div className="empathy-image mx-auto">
            <img
              src="/assets/img/Sib/EmpathyMap.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-7"></div>
          <p className="px-4 px-sm-6">
            <h3 className="fs18 fssm32 fw-bold inter text-center mt-8">
              Empathy Map's Takeaway:
            </h3>
            <ul className="mx-0 mt-4">
              <li>Trust and Reliability:</li>
              <li>
                Values assurance about cake quality before ordering online.
              </li>
              <li>Ease of Ordering</li>
              <li>Desires a quick and simple ordering process.</li>
              <li>
                Expect high-quality photos, ingredient listings, and transparent
                pricing.
              </li>
              <li>Preference for Homemade Cakes.</li>
              <li>
                Appreciates fresh, healthy, customizable, and unique
                ingredients.
              </li>
              <li>Concerns about Online Orders.</li>
              <li>Worries about potential cake damage during delivery.</li>
              <li>Seeks clarity on pickup timelines.</li>
              <li>Expectations from the Bakery Website.</li>
              <li>
                Look for pricing, location, hours of operation, bestsellers,
                delivery/pickup dates, customer reviews, and detailed ingredient
                lists.
              </li>
            </ul>
            <div className="py-5"></div>
            <h3 className="fs18 fssm32 fw-bold inter text-center mt-8">
              4. Competitive Analysis
            </h3>
            Conducting competitive analysis for designing a local Bakery website
            is crucial because it helps us to understand the market landscape,
            identify competitors' strengths and weaknesses, and find
            opportunities for a unique design.
          </p>
          <div className="py-7"></div>
          <div className="competitor-image mx-auto">
            <img
              src="/assets/img/Sib/Competitor.png"
              alt=""
              className="img-fluid"
            />
          </div>{" "}
          <div className="py-7"></div>
          <p className="px-4 px-sm-6">
            <h3 className="fs18 fssm32 fw-bold inter text-center mt-8">
              Competitive Analysis's Takeaway:
            </h3>
            <ul className="mx-0">
              <li>Determine minimal and modern design.</li>
              <li>How to present business values and legitimacy.</li>
              <li>
                How can the website answer users' important questions via FAQ.
              </li>
              <li>Prioritize filter based on users' needs.</li>
              <li>
                Identify key navigation categories and optimize the search bar
                functionality
              </li>
            </ul>
          </p>
          <div className="py-9"></div>
          <div className="header">
            <span className="inter">Define</span>
          </div>
          <div className="py-5 py-sm-8"></div>
          <p className="mx-8 mx-sm-auto">
            <h3 className="fs18 fssm32 fw-bold inter text-center mt-8">
              Persona
            </h3>
            We gathered all the information collected during the Discovery phase
            to create a persona, providing us with better insights into user
            needs and behaviors. This persona guided the design process,
            ensuring interfaces meet user expectations and drive engagement.
          </p>
          <div className="py-5 py-sm-4"></div>
          <div className="persona-image mx-auto">
            <img
              src="/assets/img/Sib/Persona.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-5 py-sm-7"></div>
          <p className="mx-8 mx-sm-auto">
            <h3 className="fs18 fssm32 fw-bold inter text-center mt-8">
              User Flow
            </h3>
            During the define phase, creating a user flow involves mapping out
            the sequential steps and interactions a user will experience while
            navigating a digital product or website.{" "}
          </p>
          <div className="py-5 py-sm-11"></div>
          <div className="userflow-image mx-auto">
            <img
              src="/assets/img/Sib/UserFlow.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-5 py-sm-7"></div>
          <p className="mx-8 mx-sm-auto">
            <h3 className="fs18 fssm32 fw-bold inter text-center mt-8">
              Task Flow
            </h3>
            We created a Task Flow to define the main task for a user. Following
            it helped us determine if we successfully reached the main goal,
            which is the ability to make purchases via the web. We also used the
            Task Flow to define tasks for the testers.{" "}
          </p>
          <div className="py-5 py-sm-9"></div>
          <div className="taskflow-image mx-auto">
            <img
              src="/assets/img/Sib/TaskFlow.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-5 py-sm-6"></div>
          <p className="mx-8 mx-sm-auto">
            <h3 className="fs18 fssm32 fw-bold inter text-center mt-8">
              Sitemap
            </h3>
          </p>
          <div className="py-5 py-sm-4"></div>
          <div className="sitemap-image mx-auto">
            <img
              src="/assets/img/Sib/Sitemap.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-7 py-sm-8"></div>
          <div className="header">
            <span className="inter">develop</span>
          </div>
          <p className="mx-8 mx-sm-auto">
            <h3 className="fs18 fssm32 fw-bold inter text-center ">
              Mid Fidelity Wireframes
            </h3>
            For better results from testers and to enhance stakeholder
            comprehension, we opted to initially design mid-fidelity versions of
            main pages. Upon receiving initial approval, we proceeded to create
            a UI Kit and swiftly transitioned to high-fidelity designs. This
            approach facilitated clearer understanding for testers and enabled
            us to identify user pain points efficiently.
          </p>
          <div className="py-7 py-sm-8"></div>
          <div className="midfidelity-image mx-auto">
            <img
              src="/assets/img/Sib/MidFidelity.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-7 py-sm-4"></div>
          <p className="mx-8 mx-sm-auto">
            The homepage was deemed the most crucial page in this project,
            requiring visual appeal to captivate users and prompt them to scroll
            and navigate the site. Consequently, we designed several iterations
            of the homepage, seeking stakeholder approval for the final version.
          </p>
          <div className="py-7 py-sm-4"></div>
          <div className="version-image mx-auto">
            <img
              src="/assets/img/Sib/Version123.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <p className="mx-8 mx-sm-auto">
            <h3 className="fs18 fssm33 fw-bold inter text-center ">
              Visual Design
            </h3>
            We brainstormed and collected essential design values, translating
            them into visual concepts via mood boards. This guided our selection
            of color palettes, typography, and visual elements, ensuring a
            cohesive and inspiring user experience.
            <div className="py-7 py-sm-0"></div>
            <h3 className="fs18 fssm33 fw-bold inter text-center ">
              Mood Board
            </h3>
          </p>
          <div className="py-7 py-sm-3"></div>
          <div className="mood-image mx-auto">
            <img
              src="/assets/img/Sib/MoodBoard.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-4 py-sm-1"></div>
          <p className="mx-8 mx-sm-auto">
            <h3 className="fs18 fssm33 fw-bold inter text-center ">Ui Kits</h3>
          </p>
          <div className="py-4 py-sm-4"></div>
          <div className="uikit-image mx-auto">
            <img src="/assets/img/Sib/UIKit.png" alt="" className="img-fluid" />
          </div>
          <div className="py-4 py-sm-5"></div>
          <div className="row w-72 mx-auto align-items-center mt-9">
            <div className="col-md-6">
              <img
                src="/assets/img/Sib/WeOptimize.png"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-6 fs16 mt-6 mt-md-0 ps-16 pe-10">
              We optimized component design for maximum reusability by defining
              various properties. For example, our versatile card component
              accommodates different products with varying images, prices,
              ingredients, and descriptions, ensuring adaptability across
              diverse content.
            </div>
          </div>
          <div className="py-6 py-sm-13"></div>
          <div className="header">
            <span className="inter">DELIVER</span>
          </div>
          <div className="py-6 py-sm-2"></div>
          <p className="mx-8 mx-sm-auto">
            <h3 className="fs18 fssm33 fw-bold inter text-center mt-16">
              High Fidelity Wireframes
            </h3>
            After conducting comprehensive research, we have transformed our
            insights into concrete visual representations through the creation
            of a series of sketches.
          </p>
          <div className="py-6 py-sm-8"></div>
          <div className="highfidelity-container">
            <div className="highfidelity-image mx-auto">
              <img
                src="/assets/img/Sib/HighFidelity.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <p className="mx-8 mx-sm-auto">
            <h3 className="fs18 fssm33 fw-bold inter text-center mt-16">
              Usability Test Report{" "}
            </h3>
            In our usability test conducted on Maze with 32 users, we tasked
            them with purchasing two items, specifying a date and time for
            pick-up, and applying a discount code. The objective was to evaluate
            the entire process, from selection to payment confirmation. The
            results indicated that nearly all users successfully completed the
            tasks. The Maze report and our observations verified that users
            navigated through the process smoothly, expressing satisfaction with
            the purchasing experience. However, during our observations, we
            gathered valuable feedback that led to identified areas for
            improvement. These insights guided subsequent iterations, which are
            detailed in the following section of this report.{" "}
          </p>
          <div className="py-13"></div>
          <div className="usability-image mx-auto text-center">
            <img
              src="/assets/img/Sib/pleas-buy.jpg"
              alt=""
              className="img-fluid w-100"
            />
          </div>
          <div className="py-1"></div>
          <div className="usability-image mx-auto">
            <img
              src="/assets/img/Sib/Usability.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-2"></div>
          <p className="mx-8 mx-sm-auto">
            <h3 className="fs18 fssm33 fw-bold inter text-center mt-16">
              Iterations{" "}
            </h3>
            Testing with 32 people on Maze showed that we succeeded in guiding
            users to achieve the main goal of the website. Next, we sought user
            opinions about the entire pages to discover any areas that might
            have appeared complex for them. Alongside the Maze test, we
            conducted online testing sessions, asking testers to share their
            screens and browse the Sib Bakery prototype while thinking aloud. We
            made changes based on the testers' feedback. Here are some key
            changes: <div className="py-4"></div>
            <h3 className="fs16 fssm19 fw-bold inter text-center mt-16">
              Home page iterations{" "}
            </h3>
            During the iteration phase of our local bakery website project, the
            stakeholder favored emphasizing pick-up options and utilizing the
            delivery service through the Doordash website. In the initial
            design, we placed the pick-up address in the navigation bar with a
            Doordash icon. However, A/B testing revealed that many customers
            valued delivery. The Doordash icon caused confusion as users didn't
            immediately associate it with delivery, prompting continued searches
            for a dedicated delivery feature. After discussions with the
            stakeholder.
            <br />
            <br />
            <ul className="mx-0 mt-6">
              <li>
                Removed the Doordash icon from the navigation bar and added a
                delivery option on the product page.
              </li>
              <li>
                Changed the button label "order & pick up at the store" to just
                "Start order."
              </li>
              <li>
                Removed the delivery option by Doordash below the hero image.
              </li>
            </ul>
          </p>
          <div className="py-16"></div>
          <div className="py-7"></div>
          <div className="iteration1-image mx-auto">
            <img
              src="/assets/img/Sib/Iteration1.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <p className="mx-8 mx-sm-auto">
            <h3 className="fs16 fssm18 fw-bold inter text-center mt-16">
              Product page iteration{" "}
            </h3>
            In the initial version of our product page, we had a single
            "gluten-free" filter without a title, but during testing, we
            discovered it wasn't clear or user-friendly. Additionally, there was
            insufficient space for potential additional filters. Consequently,
            we redesigned the filter entirely, shifting from a singular focus to
            filtering content, making it more user-friendly and allowing for the
            inclusion of multiple filters in the future. This involved changing
            from a single "Gluten-free" filter to a dropdown for various
            filters.
          </p>
          <div className="py-8"></div>
          <div className="iteration2-image mx-auto">
            <img
              src="/assets/img/Sib/Iteration2.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <p className="mx-8 mx-sm-auto">
            <h3 className="fs16 fssm19 fw-bold inter text-center mt-16">
              Shopping Cart page iteration{" "}
            </h3>
            In our initial design on the shopping cart page, users only had the
            option for pick-up. For delivery, users had to click on the Doordash
            icon on the first page, redirecting them to the Doordash website to
            place their order, with no direct option to select delivery from our
            page. After A/B testing, we identified user frustration in
            navigating back, leading to incomplete tasks. Following
            brainstorming, we addressed this issue by incorporating both pick-up
            and delivery options directly on the same page. Now, users can
            conveniently choose either option, leading to heightened
            satisfaction.
          </p>
          <div className="py-7"></div>
          <div className="iteration3-image mx-auto">
            <img
              src="/assets/img/Sib/Iteration3.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <p className="mx-8 mx-sm-auto">
            <h3 className="fs18 fssm33 fw-bold inter text-center mt-12 mt-sm-16">
              Final Design
            </h3>
          </p>
          <section className="description">
            <div className="row w-88">
              <div className="col-8 d-flex flex-column align-items-center justify-content-center">
                <div style={{ width: "fit-content0" }}>
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    preload="auto"
                    controls={false}
                    style={{}}
                    className="w-100"
                    alt=""
                    src={"/assets/img/Sib/IMG_141.mp4"}
                  >
                    <source type="video/mp4" />
                  </video>
                </div>
                <a
                  href="https://www.figma.com/proto/oFpsaZGeE9CgBcFviXHzbT/Sib-Bakery-(Portfolio)?page-id=0%3A1&type=design&node-id=1-170&viewport=1597%2C1598%2C0.11&t=hLmTYCcMyF8Ksl8n-1&scaling=min-zoom&starting-point-node-id=1%3A170&mode=design"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="inter">Web Prototype</button>
                </a>
              </div>
              <div className="col-4 d-flex flex-column align-items-center justify-content-center">
                <div style={{ width: "fit-content" }}>
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    preload="auto"
                    controls={false}
                    style={{}}
                    className="w-100"
                    alt=""
                    src={"/assets/img/Sib/IMG_142.mp4"}
                  >
                    <source type="video/mp4" />
                  </video>{" "}
                </div>
                <a
                  href="https://www.figma.com/proto/oFpsaZGeE9CgBcFviXHzbT/Sib-Bakery-(Portfolio)?page-id=1%3A19679&type=design&node-id=1-21419&viewport=945%2C3088%2C0.15&t=MjktN2Q56eY5JPld-1&scaling=scale-down&mode=design"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="inter">Mobile Prototype</button>
                </a>
              </div>
            </div>
            <div className="py-6 py-sm-16"></div>
          </section>
          <div className="header">
            <span className="inter">REFLECTIONS</span>
          </div>
          <p className="px-4 px-sm-6 mt-6 mt-sm-0">
            <h3 className="fs16 fssm19 fw-bold inter text-center mt-16">
              What did I learn?
            </h3>
            Being a member of the team allowed me to learn how important
            constant communication can be, particularly at the early stages of
            the project. 
            <br />
            <br />
            In my role as a UI/UX designer, I have learned the necessity of
            iterations and how to cope with unexpected changes and client
            expectations Evaluating the current design and testing it with non
            Iranian users to check accessibility
            <br />
            <br />
            <h3 className="fs16 fssm19 fw-bold inter text-center mt-16">
              What can we do next?
            </h3>
            Evaluating the current design and testing it with non Iranian users
            to check accessibility
            <br />
          </p>
        </section>
      </div>
    </Main>
  );
}
