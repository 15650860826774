import Main from "../layouts/Main";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./Other.css";
export default function Other() {
  return (
    <Main headerSecondary>
      <div id="other">
        <section className="hero">
          <img src="/assets/img/Other/Other_Banner.png" alt="" className="" />
          <h1>My Other Works</h1>
          <h4>I'm more than that</h4>
        </section>
        <section className="main">
          <div className="py-6"></div>
          <p className="px-4 px-sm-6">
            Prior to becoming a UX/UI designer, I worked as a graphic designer
            and art instructor. Following my foray into UX/UI design, my
            interests expanded to include vector illustration, various painting
            styles, motion graphics, character design, and 3D modeling.
          </p>
          <div className="py-4 py-sm-9"></div>
          <div className="row artworks">
            <div className="col-sm-4">
              <img
                src="/assets/img/Other/_1.jpg"
                alt=""
                className="img-fluid"
              />
              <div className="fs20 fw-semibold py-8 text-center">Flyer</div>
              <img
                src="/assets/img/Other/_2.jpg"
                alt=""
                className="img-fluid"
              />
              <div className="fs20 fw-semibold py-8 text-center">
                Window Sticker
              </div>

              <img
                src="/assets/img/Other/_3.jpg"
                alt=""
                className="img-fluid"
              />
              <div className="fs20 fw-semibold py-8 text-center">
                For Sale Sign
              </div>

              <img
                src="/assets/img/Other/_4.jpg"
                alt=""
                className="img-fluid"
              />
              <div className="fs20 fw-semibold py-8 text-center">
                3D Modeling
              </div>

              <img
                src="/assets/img/Other/_5.jpg"
                alt=""
                className="img-fluid"
              />
              <div className="fs20 fw-semibold py-8 text-center">
                Color Pencil
              </div>

              <img
                src="/assets/img/Other/_6.jpg"
                alt=""
                className="img-fluid"
              />
              <div className="fs20 fw-semibold py-8 text-center">
                Business Card{" "}
              </div>
            </div>
            <div className="col-sm-4">
              <img
                src="/assets/img/Other/_7.jpg"
                alt=""
                className="img-fluid"
              />
              <div className="fs20 fw-semibold py-8 text-center">
                Rollup Banner
              </div>

              <img
                src="/assets/img/Other/_8.jpg"
                alt=""
                className="img-fluid"
              />
              <div className="fs20 fw-semibold py-8 text-center">
                Window Sticker
              </div>

              <img
                src="/assets/img/Other/_9.png"
                alt=""
                className="img-fluid"
              />
              <div className="fs20 fw-semibold py-8 text-center">
                3D Modeling
              </div>

              <img
                src="/assets/img/Other/_10.jpg"
                alt=""
                className="img-fluid"
              />
              <div className="fs20 fw-semibold py-8 text-center">
                3D Modeling
              </div>

              <img
                src="/assets/img/Other/_11.jpg"
                alt=""
                className="img-fluid"
              />
              <div className="fs20 fw-semibold py-8 text-center">
                Character Design
              </div>

              <img
                src="/assets/img/Other/_12.jpg"
                alt=""
                className="img-fluid"
              />
              <div className="fs20 fw-semibold py-8 text-center">
                Logo Design
              </div>
            </div>
            <div className="col-sm-4">
              <img
                src="/assets/img/Other/_13.jpg"
                alt=""
                className="img-fluid"
              />
              <div className="fs20 fw-semibold py-8 text-center">
                Fast Food Menu
              </div>

              <img
                src="/assets/img/Other/_14.jpg"
                alt=""
                className="img-fluid"
              />
              <div className="fs20 fw-semibold py-8 text-center">Car Wrap</div>

              <img
                src="/assets/img/Other/_15.jpg"
                alt=""
                className="img-fluid"
              />
              <div className="fs20 fw-semibold py-8 text-center">
                3D Modeling
              </div>

              <img
                src="/assets/img/Other/_17.jpg"
                alt=""
                className="img-fluid"
              />
              <div className="fs20 fw-semibold py-8 text-center">
                Oil Painting
              </div>

              <img
                src="/assets/img/Other/_18.png"
                alt=""
                className="img-fluid"
              />
              <div className="fs20 fw-semibold py-8 text-center">
                Oil Painting
              </div>

              <img
                src="/assets/img/Other/_19.jpg"
                alt=""
                className="img-fluid"
              />
              <div className="fs20 fw-semibold py-8 text-center">
                Oil Painting
              </div>
            </div>
          </div>
          <div className="py-4 py-sm-9"></div>
          <SimpleSlider />
          <div className="py-4 py-sm-9"></div>
          <div className="header">
            <span className="inter">Illustrations</span>
          </div>
          {/* <div className="illustrations-icon-image mx-auto">
            <img
              src="/assets/img/Other/Illustrations_icon.png"
              alt=""
              className="img-fluid"
            />
          </div> */}
          <div className="illustrations-image mx-auto d-none d-sm-block">
            <img
              src="/assets/img/Other/Illustrations.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="illustrations-image mx-auto d-block d-sm-none">
            <img
              src="/assets/img/Other/1.jpg"
              alt=""
              className="img-fluid mb-3"
            />
            <img
              src="/assets/img/Other/2.jpg"
              alt=""
              className="img-fluid mb-3"
            />
            <img
              src="/assets/img/Other/3.jpg"
              alt=""
              className="img-fluid mb-3"
            />
            <img
              src="/assets/img/Other/4.jpg"
              alt=""
              className="img-fluid mb-3"
            />
            <img
              src="/assets/img/Other/5.jpg"
              alt=""
              className="img-fluid mb-3"
            />
            <img
              src="/assets/img/Other/6.jpg"
              alt=""
              className="img-fluid mb-3"
            />
            <img
              src="/assets/img/Other/7.jpg"
              alt=""
              className="img-fluid mb-3"
            />
            <img
              src="/assets/img/Other/8.jpg"
              alt=""
              className="img-fluid mb-3"
            />
            <img
              src="/assets/img/Other/9.jpg"
              alt=""
              className="img-fluid mb-3"
            />
            <img
              src="/assets/img/Other/10.jpg"
              alt=""
              className="img-fluid mb-3"
            />
            <img
              src="/assets/img/Other/11.jpg"
              alt=""
              className="img-fluid mb-3"
            />
            <img
              src="/assets/img/Other/12.jpg"
              alt=""
              className="img-fluid mb-3"
            />
            <img
              src="/assets/img/Other/13.jpg"
              alt=""
              className="img-fluid mb-3"
            />
            <img
              src="/assets/img/Other/14.jpg"
              alt=""
              className="img-fluid mb-3"
            />
            <img
              src="/assets/img/Other/15.jpg"
              alt=""
              className="img-fluid mb-3"
            />
          </div>
          <div className="py-5 py-sm-10"></div>
          <div className="header">
            <span className="inter">Motion Graphic</span>
          </div>
          <div className="py-5 py-sm-10"></div>
          <div className="motion-video mx-auto">
            <video
              // autoPlay
              loop
              // muted
              playsInline
              controls
              preload="auto"
              className="w-100"
            >
              <source src={"/assets/video/farm1.mp4"} type="video/mp4" />
            </video>
          </div>
          <div className="py-3 py-sm-8"></div>
          <div className="farm-image mx-auto">
            <img
              src="/assets/img/Other/Motion_Other.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="py-16"></div>
        </section>
      </div>
    </Main>
  );
}
function SimpleSlider() {
  const settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    adaptiveHeight: true,
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div className="w-100">
          <img src="/assets/img/Other/slide1.jpg" alt="" className="w-100" />
        </div>
        <div className="w-100">
          <img src="/assets/img/Other/slide2.jpg" alt="" className="w-100" />
        </div>
        <div className="w-100">
          <img src="/assets/img/Other/slide3.jpg" alt="" className="w-100" />
        </div>
        <div className="w-100">
          <img src="/assets/img/Other/slide4.jpg" alt="" className="w-100" />
        </div>
        <div className="w-100">
          <img src="/assets/img/Other/slide5.jpg" alt="" className="w-100" />
        </div>
        <div className="w-100">
          <img src="/assets/img/Other/slide6.jpg" alt="" className="w-100" />
        </div>
        <div className="w-100">
          <img src="/assets/img/Other/slide7.jpg" alt="" className="w-100" />
        </div>
        <div className="w-100">
          <img src="/assets/img/Other/slide8.jpg" alt="" className="w-100" />
        </div>
        <div className="w-100">
          <img src="/assets/img/Other/slide9.jpg" alt="" className="w-100" />
        </div>
        <div className="w-100">
          <img src="/assets/img/Other/slide10.jpg" alt="" className="w-100" />
        </div>
        <div className="w-100">
          <img src="/assets/img/Other/slide11.jpg" alt="" className="w-100" />
        </div>
        <div className="w-100">
          <img src="/assets/img/Other/slide12.jpg" alt="" className="w-100" />
        </div>
        <div className="w-100">
          <img src="/assets/img/Other/slide13.jpg" alt="" className="w-100" />
        </div>
        <div className="w-100">
          <img src="/assets/img/Other/slide14.jpg" alt="" className="w-100" />
        </div>
        <div className="w-100">
          <img src="/assets/img/Other/slide15.jpg" alt="" className="w-100" />
        </div>
        <div className="w-100">
          <img src="/assets/img/Other/slide16.jpg" alt="" className="w-100" />
        </div>
      </Slider>
    </div>
  );
}
